<template>
    <section class="trial-express-signup-thank-you">
        <div class="a-container">
            <express-signup-header class="header" v-bind="header" />
            <transition name="express-signup-slide-up">
                <div v-if="!isAnimation" class="wrapper">
                    <h1 v-if="title" class="title">
                        {{ title }}
                    </h1>
                    <p v-if="lead" class="lead" v-html="lead" />
                </div>
            </transition>
        </div>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </section>
</template>

<script>
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import ExpressSignupHeader from '@core/slices/pages/express-signup-shared/express-signup-header.vue';
import formHelper from '@utils/form.js';

export default {
    name: 'SExpressSignupTrialThankYou',
    components: {
        ExpressSignupHeader,
        ExpressSignupFooter,
    },
    props: {
        header: {
            type: Object,
            required: false,
            default: undefined,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        lead: {
            type: String,
            required: false,
            default: '',
        },
        footer: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            isAnimation: true,
        };
    },
    mounted() {
        this.isAnimation = false;

        formHelper.sendDataLayer({
            eventCategory: 'form',
            eventAction: 'submit_form_success',
            eventLabel: 'express_signup_demo',
            // WEB-44946
            formTimer: formHelper.getSecondsOnPage(),
        });
    },
};
</script>

<style lang="postcss" scoped>
.trial-express-signup-thank-you {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 100vh;
    overflow-x: hidden;
    background: linear-gradient(
        180deg,
        var(--av-brand-secondary-accent) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    color: var(--av-nav-primary);
    text-align: center;

    .wrapper {
        @mixin colls 12;
        margin: 80px auto 40px;
        @media (--viewport-tablet) {
            margin: 20vh auto 40px;
        }
        @media (--viewport-desktop) {
            @mixin colls 9;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 8;
        }
        @media (--viewport-desktop-large) {
            max-width: 620px;
        }
        .title {
            @mixin display;
            margin-bottom: 64px;
            font-weight: 600;
            @media (--viewport-tablet) {
                @mixin large;
                margin-bottom: 32px;
                line-height: 64px;
            }
        }
        .lead {
            @mixin title;
            &:deep(a) {
                color: var(--av-brand-secondary);
                text-decoration: none;
            }
        }
    }
    .footer {
        width: 100%;
        margin-top: 67px;
        padding-bottom: 40px;
        align-self: flex-end;
        @media (--viewport-tablet) {
            margin: 0;
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(50%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
