<template>
    <header class="s-cpoc-header" :class="{ ...className, ...sliceClassName }">
        <div class="a-container">
            <div class="s-cpoc-header__content">
                <div ref="mainHeaderLogo" class="s-cpoc-header__logo-container">
                    <dynamic-link :to="logoLink">
                        <a-msp-logo class="s-cpoc-header__logo" />
                    </dynamic-link>
                </div>
                <div v-if="menuIsAvailable" ref="mainHeaderMenu" class="s-cpoc-header__nav">
                    <ul id="s-cpoc-header-menu" class="s-cpoc-header__menu">
                        <li
                            v-for="(item, index) in headerMenu"
                            :key="`menu-item-${index}`"
                            class="s-cpoc-header__menu-item"
                            :class="{ 's-cpoc-header__menu-item_active': item.id === activeTabId }"
                        >
                            <button
                                :ref="`menuLink_${item.id}`"
                                class="s-cpoc-header__menu-link"
                                type="button"
                                :class="menuItemClassName(item)"
                                @click.prevent.stop="onHeaderMenuLinkClick(item.id)"
                            >
                                {{ item.name }}
                            </button>
                        </li>
                    </ul>
                </div>
                <button
                    v-if="panelIsAvailable"
                    ref="menuToggler"
                    class="menu-toggler"
                    :class="{ 'menu-toggler_state_active': isActive, noText: showMenuTogglerText }"
                    type="button"
                    tabindex="1"
                    @click.prevent.stop="toggleMenu"
                >
                    <span class="menu-toggler__caption menu-toggler__caption-default-text">{{ upText }}</span>
                    <span class="menu-toggler__caption menu-toggler__caption-opened-text">{{ downText }}</span>
                    <a-glyph class="menu-toggler__icon" :name="getMenuTogglerIcon" />
                </button>
            </div>
        </div>
        <div
            v-show="isActive"
            ref="menu"
            class="s-main-menu"
            :class="{ 's-main-menu_centered': activeTab && activeTab.type === 'centered' }"
            :style="mainMenuStyle"
        >
            <button
                v-if="!hasInfo"
                class="s-main-menu__closer"
                type="button"
                aria-label="Close menu"
                @click="hideMenu"
            >
                <a-glyph class="s-main-menu__closer-icon" name="times" />
            </button>
            <div class="s-main-menu--mobile-accordion">
                <div class="s-main-menu__accordion-wrapper">
                    <a-accordion class="s-main-menu__accordion">
                        <a-accordion-item v-for="(item, index) in getTabs" :key="`menu-tab-${index}`" :title="item.name">
                            <s-menu-tab
                                :id="item.id"
                                :menu="item.data"
                                :active-tab-id="activeTabId"
                                :type="item.type"
                            />
                        </a-accordion-item>
                    </a-accordion>
                </div>
            </div>
            <div class="s-main-menu--desktop-accordion">
                <div v-if="hasInfo" class="s-main-menu__tab-information">
                    {{ activeTab.info }}
                    <button
                        class="s-main-menu__closer"
                        type="button"
                        aria-label="Close menu"
                        @click="hideMenu"
                    >
                        <a-glyph class="s-main-menu__closer-icon" name="times" />
                    </button>
                </div>
                <div class="s-main-menu__tabs-wrapper">
                    <div class="s-main-menu__tab">
                        <s-menu-tab
                            v-if="activeTabId"
                            :id="activeTab.id"
                            ref="menuTab"
                            :menu="activeTab.data"
                            :type="activeTab.type"
                            :active-tab-id="activeTabId"
                        />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { debounce, cloneDeepWith } from 'lodash';
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import AMspLogo from '@core/components/msp-logo/msp-logo.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SMenuTab from '@core/slices/pages/main-header/menu-tab.vue';

export default {
    name: 'SBlogCpcHeader',

    components: {
        DynamicLink,
        SMenuTab,
        AGlyph,
        AAccordion,
        AAccordionItem,
        AMspLogo,
    },

    mixins: [breakpoint],

    props: {
        /**
         * Theme
         */
        theme: {
            type: String,
            default: 'dark',
            required: false,
        },

        /**
         * Ability to change the link of the logo
         */
        logoLink: {
            type: String,
            default: '/cyber-protection-center',
            required: false,
        },

        /**
         * menu is available
         */
        menuIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * panel is available
         */
        panelIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * Locale selector is available
         */
        localeSelectorIsAvailable: {
            type: Boolean,
            default: true,
        },

        /**
         * List of links to update
         */
        listToUpdate: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            STORE_SLICE_NAME: 's-main-header',
            MINI_MENU_RIGHT_WINDOW_MARGIN: 32,
            selectors: {
                menu: '.s-main-menu',
                nav: '.s-cpoc-header__nav',
            },
            isActive: false,
            lastClass: 's-main-menu-active',
            activeTabId: null,
            menuState: null,
            loginState: null,
            leftMenuMargin: 0,
            showMenuButtonsText: false,
            showMenuTogglerText: false,
        };
    },

    computed: {
        config() {
            return this.$store.state.slices.items['s-main-header'] || {};
        },

        className() {
            return {
                's-cpoc-header_theme_dark': this.theme === 'dark',
                's-cpoc-header_theme_light': this.theme === 'light',
            };
        },

        upText() {
            return this.config.upText || '';
        },

        downText() {
            return this.config.downText || '';
        },

        headerMenu() {
            return this.config.menu || {};
        },

        getMenuText() {
            return this.isActive ? this.downText : this.upText;
        },

        getMenuTogglerIcon() {
            //  TODO: Invalid prop: custom validator check failed for prop "name
            return this.isActive ? 'big-close' : 'burger-menu';
        },

        getTabs() {
            if (!this.listToUpdate) return this.headerMenu;

            const tabs = cloneDeepWith(this.headerMenu, (value) => {
                const link = this.listToUpdate.find((item) => item.to === value.to);

                if (link?.queryString) {
                    const queryString = new URLSearchParams(link.queryString);
                    return { ...value, to: `${value.to}?${queryString.toString()}` };
                }

                return undefined;
            });

            return tabs;
        },

        activeTab() {
            return this.activeTabId
                ? this.getTabs.find((tab) => tab.id === this.activeTabId)
                : null;
        },

        hasInfo() {
            return this.activeTab?.info || false;
        },

        mainMenuStyle() {
            return { left: `${this.leftMenuMargin}px` };
        },

        sliceClassName() {
            return {
                's-cpoc-header_state_active': this.isActive,
                's-cpoc-header_state_active-light': this.hasInfo,
                's-cpoc-header--menu-active': this.isActive,
            };
        },
    },

    watch: {
        isDesktop() {
            this.hideMenu();
        },

        async isContactMenuShown() {
            await this.$nextTick();

            this.setContactMenuPosition();
        },

        menuState() {
            if (this.menuState === 'hover') {
                document.addEventListener('mousemove', this.removeHoverMenuState);
            }

            if (this.menuState === 'fix') {
                document.addEventListener('click', this.removeFixedMenuState);
                document.addEventListener('scroll', debounce(this.setMenuCoordsListener, 100));
            }

            if (this.menuState === null) {
                document.removeEventListener('mousemove', this.removeHoverMenuState);
                document.removeEventListener('click', this.removeFixedMenuState);
                document.removeEventListener('scroll', debounce(this.setMenuCoordsListener, 200));
            }
        },

        loginState() {
            if (this.loginState === 'fix') {
                document.addEventListener('click', this.removeFixedLoginState);
                document.addEventListener('scroll', this.setLoginCoordsListener);
            }

            if (this.loginState === null) {
                document.removeEventListener('click', this.removeFixedLoginState);
                document.removeEventListener('scroll', this.setLoginCoordsListener);
            }
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.calculateMenuPosition);
    },

    mounted() {
        window.addEventListener('resize', this.calculateMenuPosition);
    },

    methods: {
        setContactMenuPosition() {
            const el = this.$refs.contactMenu;
            if (!this.isContactMenuShown) {
                el.style.left = 0;
                return;
            }

            const right = el.getBoundingClientRect().right;
            const documentWidth = document.body.getBoundingClientRect().width;

            const distance = documentWidth - right;

            if (distance > 0) return;

            el.style.left = `${distance}px`;
        },
        onHeaderMenuLinkClick(id) {
            this.selectTab(id);
            this.switchMenuState('fix');
        },

        headerItemsMinDistance() {
            const MIN_DISTANCE = 48;

            const mainHeaderMenu = this.$refs.mainHeaderMenu;
            const mainHeaderButtons = this.$refs.mainHeaderButtons;
            const mainHeaderLogo = this.$refs.mainHeaderLogo;

            const mainHeaderMenuRight = mainHeaderMenu?.getBoundingClientRect().right || 0;
            const mainHeaderButtonsLeft = mainHeaderButtons?.getBoundingClientRect().left || 0;
            const mainHeaderLogoRight = mainHeaderLogo?.getBoundingClientRect().right || 0;

            const distanceFromMenu = mainHeaderButtonsLeft - mainHeaderMenuRight;
            const distanceFromLogo = mainHeaderButtonsLeft - mainHeaderLogoRight;

            return distanceFromMenu <= MIN_DISTANCE || distanceFromLogo <= MIN_DISTANCE;
        },

        menuItemClassName(item) {
            return {
                's-cpoc-header__menu-link_active': item.id === this.activeTabId,
            };
        },

        toggleMenu() {
            this.isActive = !this.isActive;
        },

        selectTab(to) {
            if (this.activeTabId === to) {
                if (this.isActive) {
                    this.activeTabId = null;
                }
                this.toggleMenu();
                return;
            }
            this.isActive = true;
            this.activeTabId = to;
        },

        switchMenuState(actionType) {
            const actionIsMenu = actionType === this.menuState;
            const notFixedHover = !(this.menuState === 'fix' && actionType === 'hover');
            if (this.isDesktop && !actionIsMenu && notFixedHover) {
                this.menuState = actionType;
                this.isActive = true;
            }

            this.$nextTick(() => this.calculateMenuPosition());
        },

        removeHoverMenuState(event) {
            if (
                this.menuState !== 'fix' &&
                !event.target.closest(this.selectors.menu) &&
                !event.target.closest(this.selectors.nav)
            ) {
                this.hideMenu();
            }
        },

        setMenuCoordsListener() {
            if (!this.isDesktop || this.$refs.menu.getBoundingClientRect().bottom > 0) return;

            this.hideMenu();
        },

        removeFixedMenuState(event) {
            if (!event.target.closest(this.selectors.menu)) {
                this.hideMenu();
            }
        },

        hideMenu() {
            this.menuState = null;
            this.isActive = false;
            this.activeTabId = null;
        },

        // WARN: don't change `function()` to `() =>` because this.$refs won't be available in this case
        setLoginCoordsListener: debounce(function setLoginCoordsListenerDebounced() {
            if (this.$refs.loginMenu.getBoundingClientRect().bottom < 0) {
                this.loginState = null;
                this.isLoginShown = false;
            }
        }, 200),

        /* used on minisite to align menu to the center of the active menu link */
        calculateMenuPosition() {
            if (!(this.$refs.menuTab && this.activeTab.type === 'centered')) {
                this.leftMenuMargin = 0;
                return;
            }

            let leftMarginRelativeToLink = 0;
            const activeLinkRefs = this.$refs?.[`menuLink_${this.activeTabId}`];

            if (activeLinkRefs?.length) {
                const activeLink = activeLinkRefs[0];
                leftMarginRelativeToLink = activeLink.getBoundingClientRect().left + activeLink.clientWidth / 2;
            }

            /* using half width because menu tab is transformed by 50% to the left */
            const menuTabHalfWidth = this.$refs.menuTab.$el.offsetWidth / 2;

            const windowWidth = window.innerWidth;

            /* changing left margin if it makes menu tab overflow window */
            if (
                leftMarginRelativeToLink + menuTabHalfWidth >
                windowWidth - this.MINI_MENU_RIGHT_WINDOW_MARGIN
            ) {
                this.leftMenuMargin =
                    windowWidth - this.MINI_MENU_RIGHT_WINDOW_MARGIN - menuTabHalfWidth;
            } else {
                this.leftMenuMargin = leftMarginRelativeToLink;
            }
        },
    },

    serverPrefetch() {
        const locale = this.$route.params.locale;
        this.$store.dispatch('slices/getSyncedData', { slice: 's-locale-selector', locale });
        this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale });
    },
};
</script>

<style lang="postcss">
.s-cpoc-header {
    position: absolute;
    top: 24px;
    width: 100%;
    z-index: 400;

    &--menu-active {
        z-index: 999;
    }

    @media (--viewport-desktop) {
        top: 32px;

        &_state {
            &_active {
                .s-cpoc-header {
                    &__menu-link {
                        &_active {
                            &::after,
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.s-cpoc-header {
            &_state {
                &_active-light {
                    .s-cpoc-header {
                        &__menu-link {
                            &_active {
                                &::after,
                                &::before {
                                    background: none;
                                }
                            }
                        }
                    }
                    .s-main-menu {
                        z-index: 100;
                    }
                }
            }
        }
    }

    &__logo {
        height: 32px;
        width: 158px;
        &-container {
            margin: auto;
            @media (--viewport-desktop) {
                margin: 0;
                margin-inline-end: 53px;
            }
            @media (--viewport-desktop-wide) {
                margin-inline-end: 148px;
            }
        }
    }

    &__content {
        z-index: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__nav {
        z-index: 100;
    }

    &__menu {
        display: none;
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        width: 100%;
        height: 0;
        background: var(--av-fixed-white);

        @media (--viewport-desktop) {
            position: static;
            height: auto !important;
            background: rgba(255, 255, 255, 0);
            display: flex;
            width: auto;
            margin-inline-end: auto;
        }

        &-item {
            padding: 10px 0;

            @media (--viewport-desktop) {
                padding: 0;
            }
            &:first-of-type {
                .s-cpoc-header__menu-link {
                    padding-inline-start: 0;
                }
            }

            &:last-of-type {
                .s-cpoc-header__menu-link {
                    padding-inline-end: 0;
                }
            }
        }

        &-link {
            @mixin paragraph-accent;

            padding: 0 12px;
            background: none;
            border: none;
            color: var(--av-nav-secondary);
            cursor: pointer;

            &::before {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 12px);
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 27px;
                height: 27px;
                background-color: rgba(253, 184, 146, 1);
                pointer-events: none;

                @media (--viewport-desktop-large) {
                    top: calc(100% + 17px);
                }
            }

            &::after {
                opacity: 0;
                content: '';
                position: absolute;
                z-index: 100;
                top: calc(100% + 17px);
                inset-inline-start: 50%;
                transform: translateX(-50%);
                width: 38px;
                height: 30px;
                background-color: rgba(253, 184, 146, 1);
                pointer-events: none;

                @media (--viewport-desktop-large) {
                    top: calc(100% + 21px);
                }
            }

            @media (--viewport-desktop) {
                position: relative;
                text-transform: capitalize;
                color: var(--av-fixed-white);
            }
        }
    }

    &__buttons {
        border: 0;
        z-index: 105;
        height: 24px;
        display: flex;
        margin-inline-start: auto;
        position: relative;
        align-items: center;

        &.noText {
            .s-cpoc-header__contact-text,
            .s-cpoc-header__login-text {
                display: none;
            }
            .s-login-menu,
            .s-contact-menu {
                top: calc(100% + 16px);
            }
        }

        &::before {
            top: 0;
            inset-inline-end: 0;
            width: 1px;
            content: '';
            height: 24px;
            position: absolute;
        }

        > div {
            position: relative;
            height: 24px;
            display: flex;
            align-items: center;

            &::before {
                width: 1px;
                position: absolute;
                content: '';
                background-color: var(--av-fixed-info-dark);
                inset-inline-start: 0;
                top: 0px;
                bottom: 0;
            }

            &:first-of-type {
                padding-inline-start: 0;
                &::before {
                    width: 0;
                }
            }

            margin-inline-end: 0;
            padding-inline-start: 12px;
            padding-inline-end: 12px;

            @media (--viewport-mobile-wide) {
                padding-inline-start: 20px;
                padding-inline-end: 20px;
            }
        }

        @media (--viewport-desktop) {
            &::before {
                display: none;
            }
        }
    }

    .menu-toggler {
        padding: 0;
        border: none;
        display: flex;
        z-index: 100;
        height: 32px;
        min-width: 48px;
        cursor: pointer;
        background: none;
        position: absolute;
        inset-inline-end: var(--container-paddings-mobile);
        align-items: center;
        justify-content: flex-end;
        margin-top: -6px;

        @media (--viewport-mobile-wide) {
            min-width: 83px;
            inset-inline-end: var(--container-paddings-mobile-wide);
        }

        &.noText {
            min-width: 40px;
            &.menu-toggler_state_active .menu-toggler__icon {
                margin-inline-end: 12px;
            }
            .menu-toggler__caption {
                display: none;
            }
        }

        &__caption {
            @mixin body;
            padding: 0 8px 0 16px;
        }

        &__caption-default-text {
            display: none;

            @media (--viewport-mobile-wide) {
                display: block;
            }
        }

        &__caption-opened-text {
            display: none;
        }

        &__icon {
            width: 32px;
            height: 32px;
            transition: none;
            fill: var(--av-fixed-white);
        }

        &_state {
            &_active {
                .menu-toggler {
                    &__caption-default-text {
                        display: none;
                    }

                    &__caption-opened-text {
                        @media (--viewport-mobile-wide) {
                            display: block;
                        }
                        color: rgba(253, 184, 146, 1);
                    }

                    &__icon {
                        width: 16px;
                        height: 16px;
                        color: rgba(253, 184, 146, 1);
                        @media (--viewport-mobile-wide) {
                            margin-inline-start: 8px;
                        }
                    }
                }
            }
        }

        @media (--viewport-desktop) {
            display: none;
        }
    }

    .s-main-menu {
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 48px;
        width: 100%;
        position: absolute;
        min-height: calc(100vh - 75px);
        background: #000;
        z-index: 100;
        border-top: 2px solid rgba(253, 184, 146, 1);
        box-shadow: var(--av-shadow-regular);

        &--mobile-accordion {
            @media (--viewport-desktop) {
                display: none;
            }
        }

        &--desktop-accordion {
            display: none;

            @media (--viewport-desktop) {
                display: block;
            }
        }

        @media (--viewport-tablet) {
            top: 56px;
        }

        @media (--viewport-desktop) {
            min-height: auto;
            top: 44px;
            width: 100vw;
            max-width: 100%;
        }

        @media (--viewport-desktop-large) {
            top: 48px;
        }

        &.s-main-menu_centered {
            inset-inline-end: auto;
            width: auto;
            transform: translateX(-50%);
            border-radius: 4px;
        }

        &__tabs-wrapper {
            position: relative;
            margin: auto;
            width: 100%;
            max-width: 1312px;
            &:deep(.s-menu-tab) {
                &__link-strong {
                    color: var(--av-fixed-white);
                }
            }
        }

        &__tab-information {
            @mixin caption;
            padding: 8px 16px;
            text-align: center;
            position: relative;
            color: var(--av-fixed-light);
            background-color: var(--av-solid-brand-accent);

            .s-main-menu {
                &__closer {
                    top: auto;
                    bottom: -64px;
                }
            }
        }

        &__disable {
            display: none;
        }

        &__accordion-wrapper {
            width: 100%;
            padding: 16px 0 0;
        }

        &__closer {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 36px;
            inset-inline-end: 32px;
            z-index: 1;
            background: none;
            border: none;
            width: 16px;
            height: 16px;
            cursor: pointer;

            &:hover {
                .s-main-menu__closer-icon {
                    fill: rgba(197, 143, 113, 1);
                }
            }

            @media (--viewport-desktop) {
                display: flex;
            }

            @media (--viewport-desktop-wide) {
                inset-inline-end: 52px;
            }

            @media (--viewport-desktop-large) {
                inset-inline-end: 64px;
            }
        }

        &__closer-icon {
            flex: 0 0 16px;
            height: 16px;
            fill: rgba(253, 184, 146, 1);
        }

        .a-accordion-item {
            &:first-of-type {
                border-top: none;
            }

            .title {
                @mixin lead-accent;
                padding: 24px 16px 24px 16px;
                @media (--viewport-tablet) {
                    @mixin title-accent;
                    padding: 24px 16px 24px 32px;
                }
            }

            .icon {
                margin-inline-end: 16px;
                @media (--viewport-tablet) {
                    margin-top: 8px;
                }
            }

            .content-wrapper {
                padding: 0;
            }

            .content {
                padding: 0;
                height: 0;
            }

            .s-menu-tab__section_type_contact-us {
                padding-top: 0;
            }
        }
    }

    &_theme {
        &_dark {
            .s-cpoc-header {
                &__menu-link {
                    font-weight: 400;
                    color: var(--av-fixed-white);
                    .a-glyph {
                        fill: var(--av-fixed-white);
                    }
                    &_active {
                        @mixin paragraph-accent;
                        color: var(--av-fixed-white);
                    }
                }
                &__buttons {
                    &::before {
                        background-color: var(--av-fixed-info-dark);
                    }
                }
            }

            .menu-toggler {
                &__icon {
                    fill: var(--av-fixed-white);
                }

                &__caption {
                    color: var(--av-fixed-white);
                }
            }
        }
    }
}

[dir='rtl'] {
    .s-cpoc-header {
        .s-main-menu {
            &.s-main-menu_centered {
                inset-inline-start: auto;
                inset-inline-end: unset;
            }
        }
    }
    .s-cpoc-header__menu-link::before,
    .s-cpoc-header__menu-link::after {
        inset-inline-start: unset;
        inset-inline-end: 50%;
    }
}
</style>
