<template>
    <div class="wrapper">
        <div class="a-container">
            <div class="blog-cpc-post-body">
                <div class="post-body">
                    <template v-for="(block, i) in postBody.blocks">
                        <div
                            v-if="block.type === 'header'"
                            :key="block.id"
                            class="post-title-wrap"
                            :class="{'no-margin': i === 0}"
                        >
                            <a v-if="block.data.anchor" :id="block.data.anchor" :name="block.data.anchor" />
                            <component
                                :is="getTitleElement(block)"
                                :id="block.id"
                                :key="i"
                                class="post-title"
                            >
                                <a-dangerous-html :content="block.data.text" tag="span" />
                            </component>
                        </div>
                        <p v-else-if="block.type === 'paragraph'" :key="`${block.id}-paragraph`" class="post-paragraph">
                            <a-dangerous-html :content="block.data.text" tag="span" />
                        </p>
                        <figure
                            v-else-if="block.type === 'image'"
                            :key="`${block.id}-image`"
                            class="picture"
                            :class="{
                                'with-border': block.data.withBorder,
                                stretched: block.data.stretched,
                                'with-bg': block.data.withBackground
                            }"
                        >
                            <a-media
                                v-if="block.data.enlarge"
                                type="screenshot"
                                :link="block.data.file.url"
                                :enlarged-image="block.data.file.url"
                                fit="cover"
                            />
                            <a-media
                                v-else
                                type="screenshot"
                                :link="block.data.file.url"
                                fit="cover"
                            />
                            <figcaption v-if="block.data.caption" class="caption">
                                {{ block.data.caption }}
                            </figcaption>
                        </figure>
                        <component
                            :is="getListElement(block)"
                            v-else-if="block.type === 'list'"
                            :key="`${block.id}-list`"
                            class="post-list"
                        >
                            <li v-for="(listItem, j) in block.data.items" :key="j" class="post-list-item">
                                <a-dangerous-html :content="listItem" tag="span" />
                            </li>
                        </component>
                        <div
                            v-else-if="block.type === 'table'"
                            :key="`${block.id}-table`"
                            class="post-table"
                            :class="getTableClass(block)"
                        >
                            <div v-for="(row, j) in block.data.content" :key="j" class="post-table-row">
                                <a-dangerous-html
                                    v-for="(cell, k) in row"
                                    :key="k"
                                    class="post-table-cell"
                                    :content="cell"
                                />
                            </div>
                        </div>
                        <div
                            v-else-if="block.type === 'highlighttool'"
                            :key="`${block.id}-highlighttool`"
                            class="post-highlight"
                        >
                            <a-dangerous-html :content="block.data.text" />
                        </div>
                        <div v-else-if="block.type === 'quotetool'" :key="`${block.id}-quotetool`" class="post-review">
                            <a-svg-sprite class="post-review-quotes" name="quote-mark" />
                            <div class="post-review-body">
                                <div v-if="block.data.title" class="post-review-title">
                                    <span class="post-review-title-quotes">"</span>
                                    {{ block.data.title }}
                                    <span class="post-review-title-quotes">"</span>
                                </div>
                                <div v-if="block.data.text" class="post-review-text">
                                    <span class="post-review-text-quotes">"</span>
                                    {{ block.data.text }}
                                    <span class="post-review-text-quotes">"</span>
                                </div>
                                <div class="post-review-author">
                                    <div v-if="block.data.author" class="post-review-name">
                                        {{ block.data.author }}
                                    </div>
                                    <div v-if="block.data.details" class="post-review-details">
                                        {{ block.data.details }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <dynamic-link
                            v-else-if="block.type === 'asset'"
                            :key="`${block.id}-asset`"
                            class="post-asset"
                            :to="block.data.link"
                            native-type="a"
                        >
                            <div class="post-asset-text">
                                <div v-if="block.data.type" class="post-asset-type">
                                    {{ block.data.type }}
                                </div>
                                <div v-if="block.data.title" class="post-asset-title">
                                    {{ block.data.title }}
                                </div>
                            </div>
                            <a-picture
                                v-if="block.data.image"
                                class="post-asset-image"
                                :link="block.data.image"
                                fit="cover"
                            />
                        </dynamic-link>
                        <div v-else-if="block.type === 'button'" :key="`${block.id}-button`" class="post-action">
                            <div v-if="block.data.text" class="post-action-text">
                                {{ block.data.text }}
                            </div>
                            <div class="post-action-button-wrap">
                                <a-button
                                    v-if="block.data.button"
                                    class="post-action-button"
                                    v-bind="block.data.button"
                                    :event="getGaEventData(ctaButtonText, block.data.title)"
                                />
                            </div>
                        </div>
                        <div v-else-if="block.type === 'codeblocktool'" :key="block.id" class="codeblock-tool">
                            <pre v-html="highlightCode(block.data)" />
                        </div>
                        <div v-else-if="block.type === 'embed'" :key="block.id" class="post-video">
                            <iframe
                                :id="block.id"
                                class="post-video-iframe"
                                type="text/html"
                                width="100%"
                                :src="`https://www.youtube.com/embed/${block.data.id}?modestbranding=1`"
                            />
                        </div>
                        <dynamic-link
                            v-else-if="block.type === 'highlightproductcard'"
                            :key="block.id"
                            class="post-highlight-product"
                            :class="[`post-highlight-product--${block.data.type}`]"
                            :to="block.data.target_url"
                        >
                            <div v-if="!isMobile" class="picture-cover">
                                <a-picture :link="block.data.file.url" fit="cover" />
                            </div>
                            <div class="post-highlight-product__content">
                                <div class="post-highlight-product__content-wrap">
                                    <a-label>
                                        {{ block.data.label_title }}
                                    </a-label>
                                    <a-dangerous-html :content="block.data.label_text" />
                                </div>
                                <a-picture v-if="isMobile" :link="block.data.file.url" />
                                <a-button type="action" :text="block.data.button_text" />
                            </div>
                        </dynamic-link>
                    </template>
                </div>
                <div v-if="socials" class="socials socials_bottom">
                    <div class="social-label">
                        {{ shareTitle }}
                    </div>
                    <a-social class="social" :social="socials" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';
import { get } from 'lodash';
import { mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCard from '@core/components/product-card/product-card.vue';
import ASocial from '@core/components/social/social.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import { LOCALE_ENGLISH_MAPPING } from '@core/slices/blog/blog-post-body/locales.js';

export default {
    name: 'SBlogCpcPostBody',
    components: {
        AMedia,
        AButton,
        APicture,
        ALabel,
        ALink,
        ASvgSprite,
        ALogo,
        ASocial,
        AProductCard,
        ADangerousHtml,
        DynamicLink,
    },
    mixins: [breakpoint],
    props: {
        socials: {
            type: Array,
            default: () => [],
        },

        author: {
            type: Object,
            required: true,
        },

        locale: {
            type: String,
            required: true,
        },

        path: {
            type: String,
            required: true,
        },

        body: {
            type: String,
            required: true,
        },

        shareTitle: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        SCROLL_MARGIN: 16,
    }),
    computed: {
        ...mapState({
            translations: (state) => state.blog.postTranslationsList?.items || [],
            translationTitle: (state) => state.slices.items.blog.translationTitle || '',
            localeLanguageMapping: (state) => state.slices.items.blog.localeLanguageMapping || {},
            company: (state) => get(state.slices.items, 'blog.companyInformation', {}),
        }),
        translationLinks() {
            const availableTranslations = this.translations.filter((translation) => translation.locale !== this.locale);

            const nonEn = availableTranslations
                .filter((translation) => !translation.locale.startsWith('en') && translation.locale !== 'es-mx');

            const en = availableTranslations.find((translation) => translation.locale === LOCALE_ENGLISH_MAPPING[this.locale]);

            return [en, ...nonEn]
                .filter(Boolean)
                .map((translation) => ({
                    locale: translation.locale,
                    path: this.path.replace(this.locale, translation.locale),
                    language: this.localeLanguageMapping[translation.locale],
                }));
        },
        postBody() {
            return JSON.parse(this.body);
        },
        authorName() {
            return this.author.title
                ? `${this.author.name} — ${this.author.title}`
                : this.author.name;
        },
        hasCompanyInformation() {
            return this.company.title && this.company.body;
        },
    },
    methods: {
        getTitleElement(block) {
            const level = Math.max(block.data?.level || 2, 2);
            return `h${level}`;
        },

        getProductToolData(data) {
            const productData = { ...data };

            if (data.links.learnLink.text && data.links.learnLink.to) {
                productData.learnLink = data.links.learnLink;
            }

            if (data.links.tryLink.text && data.links.tryLink.to) {
                productData.tryLink = data.links.tryLink;
            }

            return productData;
        },

        highlightCode(data) {
            return hljs.highlight(data.code, { language: data.language }).value;
        },

        getListElement(block) {
            const level = get(block, 'data.style', 'unordered');
            return level === 'ordered' ? 'ol' : 'ul';
        },

        getTableClass(block) {
            return block.data.withHeadings ? 'with-heading' : null;
        },

        getGaEventData(action, content) {
            return {
                category: 'Conversions',
                action,
                content,
                location: 1,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.wrapper {
    position: relative;
    padding: 32px 0 64px;
}
.blog-cpc-post-body {
    text-align: center;

    pre {
        font-family: ui-monospace, monospace;
        font-size: 14px;
        padding: 10px;
        background-color: var(--av-solid-brand-lightest);
        border: 1px solid var(--av-fixed-white-darkest);
        border-radius: 10px;
        overflow: auto;
    }

    .translations {
        border: 1px solid var(--av-brand-light);
        border-radius: 4px;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        text-align: center;

        @media(--viewport-mobile-wide) {
            text-align: start;
            justify-content: flex-start;
        }

        &-title {
            @mixin body;
            font-weight: 500;
            color: var(--av-fixed-primary);
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 8px;

            @media(--viewport-mobile-wide) {
                width: initial;
                margin-inline-end: 24px;
                text-align: start;
                display: inline;
                margin-bottom: 0;
            }
        }

        &-locales__link {
            color: var(--av-brand-secondary);
            margin-inline-end: 24px;
            margin-bottom: 8px;
            &:last-child {
                margin-inline-end: 0;
            }

            @media(--viewport-mobile-wide) {
                margin-bottom: 0;
                align-self: flex-start;
            }
        }
    }

    .socials {
        @mixin body-accent;
        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 56px auto 0;
        align-items: center;
        padding-inline-start: 8px;
    }

    .social-label {
        font-size: 11px;
        line-height: 16px;
        white-space: nowrap;
        margin-inline-end: 8px;
        display: inline-block;
        color: var(--av-fixed-primary);
        opacity: 0.9;
    }

    .social {
        justify-content: space-between;
    }

    .bottom {
        position: relative;
        margin-bottom: 48px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (--viewport-mobile-wide) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 2fr 1fr;
        }
    }

    .company-box {
        margin: 80px 0 128px;
        text-align: start;
        &__title {
            @mixin lead;
            margin-bottom: 16px;
        }
        &__body {
            @mixin body;
        }
    }
}

.post-body {
    overflow-wrap: break-word;
    color: var(--av-fixed-secondary);
    margin: 0px auto 64px;
    text-align: start;
    @media (--viewport-desktop) {
        position: relative;
        width: 760px;
        margin: 0 auto;
    }
    &:before {
        content: '';
        display: none;
        position: absolute;
        width: 1px;
        background: rgba(52, 74, 99, 0.5);
        top: -32px;
        bottom: 0px;
        inset-inline-start: -40px;
        @media (--viewport-desktop) {
            display: block;
        }
    }

    h2 {
        @mixin title-accent;
        margin-bottom: 16px;
    }

    p {
        @mixin paragraph;
        margin-bottom: 16px;
    }

    .paragraph a {
        color: var(--av-brand-secondary);

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .post-title-wrap {
        .post-title {
            @mixin display;
            font-weight: 700;
            margin-top: 48px;
            margin-bottom: 16px;
            color: var(--av-nav-primary);
            &:is(h3, h4, h5, h6) {
                @mixin lead;
                font-weight: 700;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            &:is(h3) {
                @mixin title;
            }
        }
        &.no-margin {
            .post-title {
                margin-top: 0;
            }
        }
    }

    .post-paragraph {
        @mixin lead;

        margin-bottom: 16px;
    }

    .post-list {
        margin-top: 24px;
        margin-bottom: 24px;

        @media (--viewport-mobile-wide) {
            padding-inline-start: 16px;
        }

        &:is(ol) {
            list-style-type: decimal;
            padding-inline-start: 16px;

            @media (--viewport-mobile-wide) {
                padding-inline-start: 32px;
            }

            .post-list-item {
                padding-inline-start: 3px;
            }
        }

        &:is(ul) {
            .post-list-item {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: flex-start;

                &::before {
                    content: '';
                    position: relative;
                    display: block;
                    width: 4px;
                    height: 4px;
                    margin-top: 11px;
                    border-radius: 50%;
                    flex-shrink: 0;
                    background: var(--av-nav-secondary);
                }
            }
        }
    }

    .picture {
        max-width: 764px;
        margin: 24px auto;

        @media (--viewport-desktop) {
            margin: 16px auto;
        }

        .caption {
            max-width: 80%;
            margin: 16px auto 0 auto;
            text-align: center;
            font-size: 14px;
            line-height: 24px;
            color: var(--av-fixed-light);
        }

        &.with-border {
            .image {
                border: 1px solid var(--av-brand-light);
            }
        }

        &.stretched {
            max-width: 100%;
        }

        &.with-bg {
            .image {
                background-color: var(--av-solid-brand-lightest);
            }
        }
    }

    .post-list-item {
        margin-bottom: 16px;
    }

    .post-table {
        position: relative;
        height: 100%;
        display: grid;
        margin: 0 -16px 24px;
        overflow-x: scroll;

        @media (--viewport-tablet) {
            margin: 0 0 24px;
            overflow-x: auto;
        }

        &.with-heading {
            .post-table-row:first-child {
                @mixin lead-accent;

                z-index: 1;
                padding-bottom: 6px;
                color: var(--av-nav-primary);

                @media (--viewport-tablet) {
                    padding-bottom: 0;
                }
            }
        }
    }

    .post-table-row {
        @mixin paragraph;

        display: flex;
        position: relative;
        padding-inline-start: 16px;

        @media (--viewport-tablet) {
            padding-inline-start: 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
            column-gap: 16px;
            border-bottom: 1px solid var(--av-brand-secondary-light);
        }
    }

    .post-table-cell {
        flex: 0 0 calc(50vw - 8px);
        padding: 16px 16px;
        overflow: hidden;
        outline: none;
        line-break: normal;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            height: 1px;
            background: var(--av-brand-secondary-light);
        }

        &:first-child {
            padding-inline-start: 0;
        }

        &:last-child {
            &::after {
                inset-inline-end: 16px;
            }
        }

        @media (--viewport-tablet) {
            flex: none;
            padding: 24px 0;

            &::after {
                display: none;
            }
        }
    }

    .post-highlight {
        @mixin paragraph-accent;

        margin: 24px 0;
        padding: 16px;
        text-align: center;
        border-radius: 4px;
        background: var(--av-solid-brand-accent);

        @media (--viewport-mobile-wide) {
            margin: 24px 32px;
            padding: 32px;
            font-size: 18px;
            line-height: 32px;
        }
    }

    .post-review {
        position: relative;
        margin: 40px 0;

        @media (--viewport-mobile-wide) {
            min-height: 137px;
        }
    }

    .post-review-quotes {
        display: none;
        position: absolute;
        inset-inline-start: 0;

        @media (--viewport-mobile-wide) {
            fill: var(--av-brand-secondary-accent);
            display: block;
            width: 167px;
            height: 137px;
        }
    }

    .post-review-body {
        @media (--viewport-mobile-wide) {
            padding-inline-start: 105px;
        }
    }

    .post-review-title {
        @mixin title-accent;

        margin-bottom: 16px;
        color: var(--av-nav-primary);
    }

    .post-review-title-quotes {
        @media (--viewport-mobile-wide) {
            display: none;
        }
    }

    .post-review-text-quotes {
        display: none;

        @media (--viewport-mobile-wide) {
            display: inline;
        }
    }

    .post-review-text {
        margin-bottom: 32px;
    }

    .post-review-name {
        font-weight: bold;
    }

    .post-asset {
        margin: 48px -16px 24px;
        text-decoration: none;
        display: block;
        background: var(--av-solid-brand-lightest);

        border-top: 1px solid var(--av-brand-light);
        border-bottom: 1px solid var(--av-brand-light);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

        @media (--viewport-mobile-wide) {
            margin: 48px -32px 24px;
        }

        @media (--viewport-tablet) {
            margin: 56px 32px;
            border: 1px solid var(--av-brand-light);
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
        }
    }

    .post-asset-image {
        width: calc(100% - 32px);
        margin: 0 16px;
        border-radius: 4px;
        overflow: hidden;

        @media (--viewport-mobile-wide) {
            margin: 0 32px;
            width: calc(100% - 64px);
        }

        @media (--viewport-tablet) {
            width: 244px;
            flex-shrink: 0;
            margin: 0;
            border-radius: 0;

            .a-picture__img {
                height: 100%;
                width: 100%;
                max-width: none;
                max-height: none;
            }
        }
    }

    .post-asset-text {
        padding: 24px 16px 0;

        @media (--viewport-mobile-wide) {
            padding: 24px 32px 0;
        }

        @media (--viewport-tablet) {
            padding: 24px 24px 32px;
        }
    }

    .post-asset-type {
        color: var(--av-brand-secondary);
        margin-bottom: 8px;
    }

    .post-asset-title {
        @mixin lead-accent;

        color: var(--av-nav-primary);
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .post-action {
        margin: 40px -16px;
        background: var(--av-solid-brand-lightest);
        padding: 16px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (--viewport-mobile-wide) {
            margin: 40px 0;
            padding: 24px;
        }

        @media (--viewport-desktop) {
            padding: 24px 40px;
        }
    }

    .post-action-text {
        font-size: 14px;
        line-height: 20px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin paragraph;
        }
    }

    .post-action-button-wrap {
        margin-inline-start: 16px;
        flex-shrink: 0;

        .post-action-button {
            height: 40px;
            padding: 8px 16px;
            min-width: 132px;
            color: var(--av-fixed-white);
        }
    }

    @media(--viewport-tablet) {
        .post-product-item:deep(.a-product-card__footer) {
            gap: 16px;
            flex-direction: row;
            flex-wrap: nowrap;
            margin: 20px 0 0 0;

            .a-product-card__button {
                flex: 1;
                margin: 0;
            }
        }
    }

    @media(max-width: 768px) {
        .post-product:deep(.a-product-card__footer) {
            flex-direction: column-reverse;
            justify-content: end;
            flex-grow: 1;
            .a-product-card__button {
                flex: unset;
            }
        }
    }

    .post-products {
        margin: 40px 0;
        display: grid;
        column-gap: 16px;
        row-gap: 16px;

        @media (--viewport-tablet) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .post-video {
        margin-bottom: 40px;
    }

    .post-video-iframe {
        border: 0;
        aspect-ratio: 16/9;
    }

    .post-highlight-product {
        display: flex;
        margin-inline-start: -16px;
        margin-inline-end: -16px;
        margin-bottom: 16px;
        padding-top: 24px;
        padding-bottom: 16px;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        box-shadow: 0 10px 20px 0 rgba(36, 49, 67, 0.20);
        background: var(--av-solid-brand-lightest, #F4F7FC);
        border-top: 1px solid rgba(38, 104, 197, 0.30);
        border-bottom: 1px solid rgba(38, 104, 197, 0.30);

        @media (--viewport-tablet) {
            display: flex;
            align-items: stretch;
            padding: 0;
            border-radius: 8px;
            border: 2px solid var(--av-brand-light, rgba(38, 104, 197, 0.30));
            background: var(--av-solid-brand-lightest, #F4F7FC);
            overflow: hidden;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 8px;

            @media (--viewport-tablet) {
                padding: 24px;
            }

            .a-dangerous-html {
                @mixin lead-accent;
                color: var(--av-nav-primary, #00204D);
            }

            .a-picture {
                border-radius: 6px;
                overflow: hidden;
                margin-inline: auto;
            }

            .a-button {
                width: 100%;

                @media (--viewport-tablet) {
                    width: auto;
                }
            }
        }

        &__content-wrap {
            display: flex;
            gap: 8px;
            flex-direction: column;
            align-items: flex-start;
        }

        .picture-cover {
            @media (--viewport-tablet) {
                position: relative;
                width: 242px;
                min-height: 228px;
                flex-shrink: 0;
            }
        }

        .a-picture {
            @media (--viewport-tablet) {
                position: absolute;
                inset: 0;
                margin: auto;

                &:deep(img) {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &--super {
            background: linear-gradient(133deg, #00204D 20.01%, #1769D1 99.44%);
            border: 1px solid var(--av-brand-secondary, #408BEA);

            .a-dangerous-html {
                color: var(--av-inversed-primary, #FFF);
            }
        }
    }
}
</style>

<style lang="postcss">
.blog-cpc-post-body {
    .a-dangerous-html {
        word-break: break-word;

        i {
            font-style: italic;
        }

        b {
            font-weight: bold;
        }
    }

    .social {
        .a-social {
            &__link {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0 8px;
            }

            &__icon {
                height: 16px;
                width: 16px;
                fill: rgba(253, 184, 146, 1);
            }
        }
    }
}
</style>
