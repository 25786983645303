<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158 32">
        <path fill="#fff" stroke-width="0" d="M-.01,8.16h4.74l2.28,7.22c.6,1.91,1.17,3.89,1.52,5.32h.09c.25-.99.71-2.49,1.61-5.48l2.14-7.06h4.67v15.65h-2.88v-6.74c0-1.66,0-4.07.09-5.91h-.07c-.3,1.38-.85,3.38-1.79,6.17l-2.09,6.33h-3.64l-2.32-6.95c-.48-1.61-1.22-3.93-1.63-5.55h-.07c.09,1.43.14,3.13.14,5.55v7.11H-.01v-15.65Z" />
        <path fill="#fff" stroke-width="0" d="M144.83,19.11h3.06c.25,1.7,1.82,2.51,3.91,2.51s3.27-.78,3.27-2.09c0-.9-.48-1.43-1.2-1.7s-1.52-.37-2.67-.51c-1.33-.18-2.67-.39-3.8-.94-1.2-.6-2.07-1.66-2.07-3.5,0-3.04,2.39-4.95,5.96-4.95,3.31,0,6.05,1.47,6.47,4.88h-3.04c-.32-1.7-1.66-2.46-3.54-2.46s-2.99.9-2.99,2.19,1.06,1.66,2.67,1.89c1.82.28,3.31.46,4.6.97,1.31.53,2.55,1.63,2.55,3.8,0,3.2-2.58,4.85-6.33,4.85-3.98,0-6.51-1.63-6.86-4.92Z" />
        <path fill="#fff" stroke-width="0" d="M37.12,8.16h7.59c3.66,0,5.08,2.39,5.08,4.92s-1.66,5.18-5.5,5.18h-4.26v5.55h-2.92v-15.65ZM44.3,15.62c1.54,0,2.46-.94,2.46-2.44s-.97-2.35-2.44-2.35h-4.28v4.79h4.26Z" />
        <path fill="#fff" stroke-width="0" d="M60.77,10.83h-5.18v-2.67h13.35v2.67h-5.18v12.98h-2.99v-12.98Z" />
        <path fill="#fff" stroke-width="0" d="M71.05,8.16h2.92v6.28h7.55v-6.28h2.92v15.65h-2.92v-6.65h-7.55v6.65h-2.92v-15.65Z" />
        <path fill="#fff" stroke-width="0" d="M88.08,8.16h7.62c3.38,0,4.95,2.14,4.95,4.67,0,2.26-1.13,3.93-3.36,4.62l4.05,6.35h-3.45l-3.54-5.8h-3.34v5.8h-2.92v-15.65ZM94.86,15.41c1.89,0,2.67-1.08,2.67-2.37s-.83-2.28-2.49-2.28h-4.05v4.65h3.87Z" />
        <path fill="#fff" stroke-width="0" d="M104.04,8.16h10.88v2.65h-7.98v3.75h5.75v2.62h-5.75v3.98h8.24v2.65h-11.14v-15.65Z" />
        <path fill="#fff" stroke-width="0" d="M121.97,8.16h3.98l5.73,15.65h-3.18l-1.31-3.68h-6.49l-1.31,3.68h-3.11l5.68-15.65ZM126.25,17.46l-.76-2.14c-.85-2.44-1.29-3.66-1.56-4.79h-.05c-.3,1.24-.94,3.15-1.5,4.81l-.74,2.12h4.6Z" />
        <path fill="#fff" stroke-width="0" d="M135.77,10.83h-5.18v-2.67h13.35v2.67h-5.18v12.98h-2.99v-12.98Z" />
        <path fill="#fdb892" stroke-width="0" d="M34.21,13.85v-2.64c0-.98-.67-1.83-1.63-2.06l-5.02-1.2c-.32-.08-.66-.08-.98,0l-5.02,1.2c-.95.23-1.62,1.08-1.62,2.06v4.13l11.59,2.47v.85l-4.46,2.67-4.46-2.67v-.96l-2.67-.57v1.84c0,.74.39,1.43,1.03,1.82l5.01,3c.33.2.71.3,1.09.3s.76-.1,1.09-.3l5.02-3c.63-.38,1.03-1.08,1.03-1.82v-3.33l-11.59-2.47v-1.52l4.46-1.07,4.46,1.07v1.63l2.67.57Z" />
    </svg>
</template>

<script>
export default {
    name: 'AMspLogo',
};
</script>
