<template>
    <div class="blog-cpc-card">
        <dynamic-link class="inner" :to="link" native-type="a">
            <div class="date top">
                <blog-date-localized :date="published_at" />
                <span>&nbsp;— {{ time_to_read }} {{ minReadTitle }}</span>
            </div>
            <div v-if="!hideImage" class="content">
                <a-picture
                    class="image"
                    :link="image_id || fallbackImage"
                    fit="cover"
                    :size="image_size"
                    :persist-original="persistOriginal"
                />
            </div>
            <div class="text-content">
                <div class="title" tag="div">
                    {{ format(title, maxLengthTitle) }}
                </div>
                <div class="text" tag="div">
                    {{ format(description, maxLengthDescription) }}
                </div>
            </div>
        </dynamic-link>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import BlogDateLocalized from '@core/slices/blog/shared-components/blog-date-localized/blog-date-localized.vue';

const FallbackImages = [
    'bd62a55daae50fdec0d6a6b238034607',
    '30ed349a67c19cf8c26685d868f90f47',
    'e44479aa9902652a121944102811a7bd',
    '18a373b02d45b7c3ed01a5efacfff718',
    'f62a350b7e35dfe1e3dd057a635af90b',
    '74dd033305077b8ad219667201379601',
    '701297a7531d17743bf6f7c5f50e97c8',
    '8d171c9d5dce1ca04801e22dc732de75',
];

export default {
    name: 'BlogCard',
    components: {
        DynamicLink,
        APicture,
        BlogDateLocalized,
    },
    mixins: [breakpoint],
    props: {
        /* eslint-disable vue/prop-name-casing */
        id: {
            type: Number,
            required: true,
        },

        published_at: {
            type: String,
            required: true,
        },

        time_to_read: {
            type: Number,
            required: true,
        },

        image_id: {
            type: String,
            default: null,
        },

        image_size: {
            type: String,
            default: 'blogCover',
        },

        persistOriginal: {
            type: Boolean,
            default: false,
        },

        link: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: null,
        },

        hideImage: {
            type: Boolean,
            default: false,
        },

        minReadTitle: {
            type: String,
            required: true,
        },
        maxLengthTitle: {
            type: Number,
            default: 60,
        },
        maxLengthDescription: {
            type: Number,
            default: 105,
        },
        /* eslint-enable vue/prop-name-casing */
    },

    computed: {
        fallbackImage() {
            return FallbackImages[this.id % FallbackImages.length];
        },
    },

    methods: {
        format(str, length) {
            return truncate(str, { length, separator: ' ' });
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-cpc-card {
    .text {
        color: var(--av-fixed-white);
    }

    .inner {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        &:hover {
            .title {
                color: rgba(197, 143, 113, 1);
            }
        }
        &:active {
            .title {
                color: var(--av-fixed-white);
            }
        }
    }

    .date {
        font-size: 11px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 8px;
    }

    .image {
        width: 100%;
        margin-bottom: 16px;
        border-radius: 8px;
        overflow: hidden;
        height: auto;
        @media (--viewport-desktop) {
            max-height: 194px;
        }
        @media (--viewport-desktop-large) {
            height: 194px;
        }
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            max-width: none;
            filter: sepia(60%) hue-rotate(-30deg) brightness(90%);
        }
    }

    .title {
        @mixin paragraph-accent;
        color: rgba(253, 184, 146, 1);
    }
    .text {
        @mixin body;
        color: var(--av-fixed-white);
    }
}
</style>
