<template>
    <div class="event-banner">
        <a class="event-link link" :href="cta.to" target="_blank">
            <a-picture
                class="image"
                :link="imageId"
                fit="cover"
                size="normal"
                :position="imagePosition"
            />
            <div class="content">
                <div v-if="description" class="description">{{ description }}</div>
                <div class="title-wrap">
                    <div class="title">{{ title }}</div>
                    <div v-if="cta.title" class="button-wrap">
                        <button class="event-button action button">{{ cta.title }}</button>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'EventBanner',

    components: {
        APicture,
    },

    mixins: [breakpoint],

    props: {
        imageId: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: null,
        },
        cta: {
            type: Object,
            required: true,
        },
        activeFrom: {
            type: String,
            default: '',
        },
        activeUntil: {
            type: String,
            default: '',
        },
    },

    computed: {
        imagePosition() {
            if (this.isMobile) return '66% center';
            return 'center';
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-banner {
    position: relative;
    height: 408px;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--av-fixed-white-lighter);

    .link {
        display: block;
        height: 100%;
        text-decoration: none;
        color: var(--av-fixed-white);

        &:hover {
            color: var(--av-fixed-white);
        }
    }

    .image {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
    }

    .content {
        position: relative;
        height: 100%;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;

        @media (--viewport-mobile-wide) {
            padding: 24px;
        }

        @media (--viewport-tablet) {
            padding: 24px 24px 40px;
        }
    }

    .description {
        @mixin paragraph;

        max-width: 700px;
        white-space: pre-wrap;
    }

    .title-wrap {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        @media (--viewport-mobile-wide) {
            gap: 32px;
        }
    }

    .title {
        @mixin display-strong;
        max-width: 700px;

        @media (--viewport-tablet) {
            @mixin large;
            font-weight: 700;
        }

        @media (--viewport-desktop) {
            font-size: 56px;
            line-height: 64px;
            padding-inline-end: 16px;
        }
    }

    .button-wrap {
        width: 100%;

        @media (--viewport-tablet) {
            width: initial;
        }
    }

    .button {
        padding: 8px 40px;
        width: 100%;
        color: var(--av-fixed-white);

        @media (--viewport-tablet) {
            width: auto;
        }
    }
}
</style>

<style lang="postcss">
.event-banner {
    .a-picture__img {
        width: 100%;
        height: 100%;
    }
}
</style>
