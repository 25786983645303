<template>
    <section v-if="content" class="main-page-new__hero" :class="locale">
        <div class="a-container">
            <div class="wrapper">
                <div v-if="content.complexity" class="complexity" :class="{ 'show': showComplexity }">
                    <span v-if="content.complexity.title" class="title">
                        {{ content.complexity.title }}
                    </span>
                </div>
                <div v-if="content.control" class="control" :class="{ 'show': showControl }">
                    <span v-if="content.control.title" class="title">{{ content.control.title }}</span>
                    <p v-if="content.control.lead" class="lead" v-html="content.control.lead" />
                    <a-button v-if="content.control.button" class="button" v-bind="content.control.button" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import locales from '@core/mixins/locales.js';

export default {
    name: 'SMainPageHero',
    components: {
        AButton,
    },
    mixins: [breakpoint, locales],
    props: {
        content: {
            type: Object,
            default: null,
        },
        showComplexity: {
            type: Boolean,
            default: false,
        },
        showControl: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sendEvent() {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'Button Click',
                eventLabel: 'Flicker',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new {
    &__hero {
        padding:88px 0 48px;
        color: var(--av-fixed-white);
        text-align: center;
        @media (--viewport-desktop) {
            padding: 155px 0 72px;
            text-align: start;
        }
        @media (--viewport-desktop-large) {
            padding: 155px 0 48px;
        }
        @media all and (max-height: 750px) and (min-width: 1024px) {
            padding: 130px 0 40px;
        }
        .wrapper {
            display:flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .complexity, .control {
                text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.50);
                .title {
                    @mixin large;
                    display: block;
                    font-weight:700;
                    @media (--viewport-tablet) {
                        font-size: 72px;
                        line-height:80px;
                        white-space: nowrap;
                    }
                }
            }
            .complexity {
                @mixin colls 12;
                padding-bottom:172px;
                @media (--viewport-tablet) {
                    padding-bottom:104px;
                }
                @media (--viewport-desktop) {
                    @mixin colls 6;
                    padding-bottom:0px;
                }
                .title {
                    @media (--viewport-desktop) {
                        opacity:0;
                        transition: opacity 0.25s;
                    }
                }
                &.show {
                   .title {
                        opacity:1;
                   }
                }
            }
            .control {
                @mixin colls 12;
                @media (--viewport-desktop) {
                    @mixin colls 4;
                }
                .title {
                    margin-bottom:24px;
                    @media (--viewport-desktop) {
                        opacity:0;
                        transform: translateY(-50%);
                        transition: all 0.25s;
                        margin-bottom:56px;
                    }
                    @media all and (max-height: 750px) {
                        margin-bottom: 40px;
                    }
                }
                .lead, .button {
                    @media (--viewport-desktop) {
                        opacity:0;
                        transform: translateY(50%);
                        transition: all 0.3s;
                    }
                }
                .lead {
                    margin: 0 auto 16px;
                    max-width: 634px;
                    @media (--viewport-desktop) {
                        max-width:100%;
                        margin: 0 0 24px;
                    }
                    &:deep(br) {
                        @media all and (max-height: 750px) {
                            display: none;
                        }
                    }
                }

                &.show {
                    .title, .lead, .button {
                        transform: none;
                        opacity:1;
                    }
                }
            }
        }
        &.ja-jp {
            @media (--viewport-desktop) {
                padding: 160px 0 72px;
            }
            @media all and (max-height: 750px) {
                padding: 140px 0 40px;
            }
            .wrapper {
                .complexity, .control {
                    .title {
                        @media (--viewport-tablet) {
                            font-size: 60px;
                        }
                        @media (--viewport-desktop-wide) {
                            font-size: 72px;
                        }
                    }
                }
                .control {
                    .title {
                        @media (--viewport-tablet) {
                            letter-spacing: -4px;
                        }
                        @media (--viewport-desktop-wide) {
                            letter-spacing: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
