<template>
    <div class="events-page events-home">
        <div class="main-content">
            <event-menu
                v-sticky="{ parent: '.event-menu' }"
                class="menu event-home-menu"
                v-bind="menu"
                :theme="pageTheme"
            >
                <template #locale-select>
                    <event-locale-select v-if="localeSelect.isReady" v-bind="localeSelect" @select="setLocation" />
                </template>
            </event-menu>

            <event-container :theme="pageTheme">
                <div class="title-wrap" :class="`title-wrap--${pageTheme}`">
                    <h1 class="title">
                        {{ translations.homePageTitle }}
                    </h1>
                    <div class="lead">
                        {{ translations.homePageLead }}
                    </div>
                </div>
            </event-container>

            <event-container
                v-if="bannersExist"
                id="spotlight"
                class="banner-wrap"
                :class="{ 'single-banner': banners.length === 1 }"
                :theme="pageTheme"
            >
                <event-banner-slider
                    v-if="banners.length > 1"
                    :theme="pageTheme"
                    :swap-speed="sliderSwapSpeedMs"
                    :banners="banners"
                />
                <event-banner v-else v-bind="banners[0]" />
            </event-container>

            <event-container v-if="inPersonEventsExist" class="in-person-events">
                <h2 id="in-person" class="title">
                    {{ translations.inPersonEventsTitle }}
                </h2>
                <event-card-list
                    :cards="inPersonEvents"
                    :learn-more-title="translations.learnMoreTitle"
                    :not-found-title="translations.noEventsFoundTitle"
                    :is-loading="inPersonEventsLoading"
                />
            </event-container>

            <div v-if="inPersonEventsExist && webinarsAndVirtualEventsExist" class="in-person-events-underline" />
            <event-container v-if="webinarsAndVirtualEventsExist" class="webinars-and-virtual-events">
                <h2 id="virtual" class="title">
                    {{ translations.upcomingWebinarsTitle }}
                </h2>
                <event-tab-control
                    class="types"
                    :tabs="eventsTypes"
                    :active-tab-id="webinarsCategoryId"
                    @change="webinarsAndVirtualEventsTypeChange"
                />
                <event-card-list
                    :cards="webinarsAndVirtualEvents"
                    :learn-more-title="translations.learnMoreTitle"
                    :limit="showAllUpcomingEvents ? 0 : WEBINARS_AND_VIRTUAL_TO_SHOW"
                    :not-found-title="translations.noUpcomingEventsForCategory"
                    :is-loading="webinarsAndVirtualEventsLoading"
                />
                <event-show-all-button
                    v-if="webinarsAndVirtualEvents.length > WEBINARS_AND_VIRTUAL_TO_SHOW"
                    class="show-all-button"
                    :show-all-text="translations.showAllEventsTitle"
                    :show-less-text="translations.showLessEventsTitle"
                    @toggle="webinarsAndVirtualEventsToggle"
                />
            </event-container>

            <event-container class="courses">
                <event-message v-bind="courses" />
            </event-container>

            <event-container v-if="archiveEventsExist" class="archive-events">
                <a-dangerous-html
                    id="past"
                    class="title"
                    :content="archiveEventsTitle"
                    :refinements="{ injectHrefLocale: false }"
                    tag="h2"
                />
                <event-tab-control
                    class="types"
                    :tabs="eventsTypes"
                    :active-tab-id="archiveCategoryId"
                    @change="archiveEventsTypeChange"
                />
                <event-card-list
                    :cards="archiveEvents"
                    :limit="ARCHIVE_EVENTS_COUNT"
                    :learn-more-title="translations.learnMoreTitle"
                    :not-found-title="translations.noUpcomingEventsForCategory"
                    :is-loading="archiveEventsLoading"
                />
                <div v-if="!archiveEventsLoading" class="event-button-wrap">
                    <a class="event-button" :href="archiveEventsLink.to">
                        {{ archiveEventsLink.title }}
                        <a-glyph name="arrow" />
                    </a>
                </div>
            </event-container>

            <event-container class="keynotes">
                <div class="keynotes-title-wrap">
                    <h2 id="keynotes" class="title">
                        {{ translations.keynotesTitle }}
                    </h2>
                    <a class="event-link link" :href="youtubeLink.to">{{ youtubeLink.title }} →</a>
                </div>
                <event-video-slider v-if="keynotes.length > 2" :videos="keynotes" />
                <event-video-list
                    v-else
                    :videos="keynotes"
                    :column-count="2"
                    :limit="0"
                />
                <a class="event-link link-mobile" :href="youtubeLink.to">{{ youtubeLink.title }} →</a>
            </event-container>

            <event-container class="knowledge">
                <event-message-with-image v-bind="knowledge" />
            </event-container>

            <event-container class="numbers">
                <event-numbers v-bind="numbers" />
            </event-container>
        </div>

        <s-global-footer class="footer" v-bind="SLICE_DATA_FOOTER" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import Sticky from '@core/directives/sticky.js';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import events from '@core/mixins/events.js';
import EventBanner from '@core/slices/events/event-banner/event-banner.vue';
import EventBannerSlider from '@core/slices/events/event-banner-slider/event-banner-slider.vue';
import EventCardList from '@core/slices/events/event-card-list/event-card-list.vue';
import EventMenu from '@core/slices/events/event-menu/event-menu.vue';
import EventMessage from '@core/slices/events/event-message/event-message.vue';
import EventMessageWithImage from '@core/slices/events/event-message-with-image/event-message-with-image.vue';
import EventNumbers from '@core/slices/events/event-numbers/event-numbers.vue';
import EventVideoList from '@core/slices/events/event-video-list/event-video-list.vue';
import EventVideoSlider from '@core/slices/events/event-video-slider/event-video-slider.vue';
import EventContainer from '@core/slices/events/shared-components/event-container/event-container.vue';
import EventLocaleSelect from '@core/slices/events/shared-components/event-locale-select/event-locale-select.vue';
import EventShowAllButton from '@core/slices/events/shared-components/event-show-all-button/event-show-all-button.vue';
import EventTabControl from '@core/slices/events/shared-components/event-tab-control/event-tab-control.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';

import {
    DEFAULT_COUNTRY_ID,
    WEBINARS_AND_VIRTUAL_TO_SHOW,
    ARCHIVE_EVENTS_COUNT,
    SLICE_DATA_FOOTER,
} from '@model/const/events.ts';

import socials from '@model/const/socials.ts';
import commonUtils from '@utils/common';
import eventUtils from '@utils/events.js';
import '@core/styles/sections/events.pcss';

const SLIDER_SWAP_SPEED_DEFAULT_MS = 550;

export default {
    name: 'EventsHomeComponent',

    components: {
        SGlobalFooter,
        EventContainer,
        EventMenu,
        EventCardList,
        EventMessage,
        EventMessageWithImage,
        EventNumbers,
        EventBannerSlider,
        EventVideoList,
        EventBanner,
        EventVideoSlider,
        EventTabControl,
        EventLocaleSelect,
        EventShowAllButton,
        AGlyph,
        ADangerousHtml,
    },
    directives: {
        Sticky,
    },

    mixins: [events, analytics, breakpoint],

    props: {
        dispatchLoadBanners: {
            type: Function,
            required: true,
        },
        dispatchLoadInPersonEvents: {
            type: Function,
            required: true,
        },
        dispatchLoadWebinars: {
            type: Function,
            required: true,
        },
        dispatchLoadArchiveEvents: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            DEFAULT_COUNTRY_ID,
            WEBINARS_AND_VIRTUAL_TO_SHOW,
            ARCHIVE_EVENTS_COUNT,
            SLICE_DATA_FOOTER,
            socials,
            showAllUpcomingEvents: false,
            webinarsCategoryId: 'all',
            archiveCategoryId: 'all',
            theme: 'light',
        };
    },

    computed: {
        pageTheme() {
            return this.$store.state.slices.items?.events?.homePageTheme || 'light';
        },

        sliderSwapSpeedMs() {
            return this.$store.state.slices.items?.events?.homePageSliderSwapSpeedMs || SLIDER_SWAP_SPEED_DEFAULT_MS;
        },

        bannersExist() {
            return this.banners.length > 0;
        },

        inPersonEventsExist() {
            return this.inPersonEvents.length > 0 || this.inPersonEventsLoading;
        },

        webinarsAndVirtualEventsExist() {
            return !(
                this.webinarsCategoryId === 'all' &&
                this.webinarsAndVirtualEvents.length === 0 &&
                !this.webinarsAndVirtualEventsLoading
            );
        },

        archiveEventsExist() {
            return !(
                this.archiveCategoryId === 'all' &&
                this.archiveEvents.length === 0 &&
                !this.archiveEventsLoading
            );
        },

        inPersonEventsLoading() {
            return this.eventsStore.inPersonEvents.pagination?.loading || false;
        },

        webinarsAndVirtualEventsLoading() {
            return this.eventsStore.virtualEvents.pagination?.loading || false;
        },

        archiveEventsLoading() {
            return this.eventsStore.pastEvents.pagination?.loading || false;
        },

        menu() {
            const categoryLinks = Object.entries(this.translations.categories || {})
                .filter(([_, translation]) => Boolean(translation.menuLabel))
                .map(([slug, translation]) => ({
                    text: translation.menuLabel,
                    to: `/category/${slug}/`,
                    enabled: true,
                }));

            return {
                eventsTitle: this.translations.events,
                theme: 'light',
                links: [
                    {
                        text: this.translations.menu.spotlight,
                        to: '#spotlight',
                        enabled: this.bannersExist,
                    },
                    {
                        text: this.translations.menu.inPerson,
                        to: '#in-person',
                        enabled: this.inPersonEventsExist,
                    },
                    {
                        text: this.translations.menu.virtual,
                        to: '#virtual',
                        enabled: this.webinarsAndVirtualEventsExist,
                    },
                    {
                        text: this.translations.menu.pastEvents,
                        to: '#past',
                        enabled: this.archiveEventsExist,
                    },
                    {
                        text: this.translations.menu.keynotes,
                        to: '#keynotes',
                        enabled: true,
                    },
                    ...categoryLinks,
                ],
            };
        },

        banners() {
            const banners = this.eventsStore.banners.items || [];
            return banners
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((banner) => ({
                    imageId: banner.image_id,
                    title: banner.title,
                    description: banner.description,
                    cta: {
                        to: banner.cta_button_url,
                        title: banner.cta_button_text,
                    },
                    activeFrom: banner.active_from,
                    activeUntil: banner.active_until,
                }));
        },

        eventsTypes() {
            return [
                {
                    title: this.translations.allEventsTitle,
                    id: 'all',
                },
                ...this.categories,
            ];
        },

        inPersonEvents() {
            const inPersonEvents = this.eventsStore.inPersonEvents.items || [];
            return inPersonEvents.map((event) => eventUtils.getEventCardFields(event, this.translations));
        },

        webinarsAndVirtualEvents() {
            const virtualEvents = this.eventsStore.virtualEvents.items || [];
            const categoryId = this.webinarsCategoryId !== 'all' ? parseInt(this.webinarsCategoryId, 10) : undefined;
            return virtualEvents.map((event) => eventUtils.getEventCardFields(event, this.translations, { categoryId }));
        },

        archiveEvents() {
            const archiveEvents = this.eventsStore.pastEvents.items || [];
            const categoryId = this.archiveCategoryId !== 'all' ? parseInt(this.archiveCategoryId, 10) : undefined;
            return archiveEvents.map((event) => eventUtils.getEventCardFields(event, this.translations, { categoryId }));
        },

        keynotes() {
            const keynotes = this.$store.state.slices.items.events?.keynotes || [];
            return keynotes.map((video) => ({
                time: this.videoDurationToTimeString(video.duration),
                ytId: this.getYoutubeIdFromUrl(video.url),
                imageAlt: video.title,
                locked: false,
                lockedTitle: this.translations.lockedVideoTitle,
            }));
        },

        archiveEventsTitle() {
            return commonUtils.handleHtmlInTranslations(
                this.translations.pastEventsArchiveTitle,
                [{
                    target: '@archiveLink',
                    replacement: '<a href="/archive/">$1</a>',
                }],
            );
        },

        archiveEventsLink() {
            return {
                title: this.translations.showAllPastEventsTitle,
                to: '/archive/',
            };
        },

        youtubeLink() {
            return {
                to: 'https://www.youtube.com/user/Acronis',
                title: this.translations.findMoreYoutubeTitle,
            };
        },

        courses() {
            return {
                title: this.translations.cyberFitCoursesTitle,
                lead: this.translations.cyberFitCoursesLead,
                link: {
                    to: 'https://www.acronis.com/academy/',
                    text: this.translations.learnMoreAndAccessTitle,
                },
            };
        },

        knowledge() {
            return {
                title: this.translations.knowledgeTitle,
                lead: this.translations.knowledgeLead,
                image: {
                    desktop: '87de63e82f48b2e49f451ee5ff2b6e90',
                    tablet: '19697f538c8c89a55ef2962cb5c8e0c2',
                    mobile: '6c1d3390ff5bc245f1f47d29e7a1073e',
                },
            };
        },

        numbers() {
            return {
                title: this.translations.projectsWorldwideTitle,
                numbers: [
                    {
                        value: this.translations.continentsValue,
                        label: this.translations.continents,
                    },
                    {
                        value: this.translations.livesImpactedValue,
                        label: this.translations.livesImpactedTitle,
                    },
                    {
                        value: this.translations.countriesValue,
                        label: this.translations.countries,
                    },
                ],
                link: {
                    to: 'https://acronis.org/',
                    text: this.translations.learnMoreFoundationTitle,
                },
            };
        },
    },

    mounted() {
        const countryId = this.getCountryCookie();
        if (!Number.isNaN(countryId)) {
            this.setCountry({ countryId, isConfirmed: true });
        }

        this.$on('onLocationSet', this.loadData);
    },

    methods: {
        ...mapActions({
            setCountry: 'events/setCountry',
        }),

        setLocation(countryId) {
            const needToReload = this.countryId !== countryId;
            this.setCountry({ countryId, isConfirmed: true });
            this.setCountryCookie(countryId);
            if (needToReload) {
                this.loadData();
            }
        },

        loadData() {
            this.webinarsCategoryId = 'all';
            this.archiveCategoryId = 'all';

            this.dispatchLoadBanners({ countryId: this.countryId });
            this.dispatchLoadInPersonEvents({ countryId: this.countryId });
            this.dispatchLoadWebinars({ countryId: this.countryId, webinarsCategoryId: this.webinarsCategoryId });
            this.dispatchLoadArchiveEvents({ countryId: this.countryId, archiveCategoryId: this.archiveCategoryId });
        },

        webinarsAndVirtualEventsToggle(showAll) {
            this.showAllUpcomingEvents = showAll;
        },

        webinarsAndVirtualEventsTypeChange({ id }) {
            this.webinarsCategoryId = id;
            this.dispatchLoadWebinars({ countryId: this.countryId, webinarsCategoryId: this.webinarsCategoryId });
        },

        archiveEventsTypeChange({ id }) {
            this.archiveCategoryId = id;
            this.dispatchLoadArchiveEvents({ countryId: this.countryId, archiveCategoryId: this.archiveCategoryId });
        },
    },
};
</script>

<style lang="postcss" scoped>
.events-home {
    color: var(--av-nav-primary);

    .title-wrap {
        margin-top: 106px;
        padding-bottom: 48px;

        @media (--viewport-tablet) {
            padding-bottom: 64px;
        }

        @media (--viewport-desktop-wide) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }

        .title {
            @mixin hero;
            font-weight: 700;

            @media (--viewport-tablet) {
                @mixin large;
            }

            @media (--viewport-desktop) {
                font-size: 56px;
                line-height: 64px;
            }

            @media (--viewport-desktop-wide) {
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
        }

        .lead {
            @mixin title;

            @media (--viewport-desktop-wide) {
                display: flex;
                align-items: center;
            }
        }

        &--dark {
            .title {
                color: var(--av-fixed-white);
            }

            .lead {
                color: var(--av-fixed-white);
            }
        }
    }

    .banner-wrap {
        overflow: hidden;
        padding-top: 0;
        margin-bottom: 80px;

        &.single-banner {
            padding-bottom: 112px;

            @media (--viewport-mobile-wide) {
                padding-bottom: 136px;
            }

            @media (--viewport-tablet) {
                padding-bottom: 120px;
            }
        }
    }

    .in-person-events {
        padding-bottom: 48px;
        margin-top: 80px;

        .title {
            margin-bottom: 48px;

            @media (--viewport-tablet) {
                margin-bottom: 56px;
            }
        }
    }

    .in-person-events-underline {
        height: 1px;
        background: var(--av-brand-secondary-light);
        margin-bottom: 72px;

        @media (--viewport-tablet) {
            margin-bottom: 64px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 88px;
        }
    }

    .webinars-and-virtual-events {
        padding-bottom: 48px;

        .title {
            margin-bottom: 40px;

            @media (--viewport-tablet) {
                margin-bottom: 48px;
            }

            @media (--viewport-desktop) {
                margin-bottom: 56px;
            }
        }

        .show-all-button {
            margin-top: 56px;

            @media (--viewport-tablet) {
                margin-top: 64px;
            }
        }
    }

    .archive-events {
        @mixin basic-slice-paddings;

        .title {
            margin-bottom: 48px;

            @media (--viewport-desktop) {
                margin-bottom: 56px;
            }
        }

        .event-button-wrap {
            margin-top: 56px;
            text-align: center;

            @media (--viewport-tablet) {
                margin-top: 64px;
            }
        }

        .event-button {
            display: inline-flex;
            align-items: center;
            text-align: center;
            padding: 12px 16px;
            width: 100%;
            font-weight: 700;

            @media (--viewport-tablet) {
                width: auto;
            }
        }
    }

    .courses {
        background: var(--av-brand-lightest);
        border-top: 1px solid var(--av-brand-secondary-light);
        border-bottom: 1px solid var(--av-brand-secondary-light);
    }

    .keynotes {
        overflow: hidden;
        padding: 48px 0;
        border-top: 1px solid var(--av-brand-secondary-light);
        border-bottom: 1px solid var(--av-brand-secondary-light);

        .keynotes-title-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
        }

        .title {
            margin-bottom: 0;
        }

        .link {
            @mixin lead-accent;
            display: none;
            cursor: pointer;

            @media (--viewport-tablet) {
                display: block;
            }
        }

        .link-mobile {
            @mixin lead-accent;
            margin-top: 24px;

            @media (--viewport-tablet) {
                display: none;
            }
        }
    }

    .keynotes-inner {
        overflow: hidden;
    }

    .knowledge {
        background: linear-gradient(
            180deg,
            rgba(38, 104, 197, 0.05) 0%,
            rgba(38, 104, 197, 0) 100%
        );
    }

    .numbers {
        padding-bottom: 80px;
        border-bottom: 1px solid var(--av-brand-secondary-light);

        @media (--viewport-desktop) {
            padding-bottom: 48px;
        }
    }

    .faq {
        @mixin basic-slice-paddings;
    }
}
</style>

<style lang="postcss">
.events-home {
    .title a {
        color: var(--av-brand-secondary);

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .webinars-and-virtual-events {
        .event-link {
            .a-glyph {
                fill: var(--av-brand-secondary);
            }
        }
    }

    .faq {
        .a-slice-header__title {
            margin-bottom: 40px;
        }
    }
}
</style>
