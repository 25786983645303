<template>
    <section :id="id" class="s-slice s-header-lp-one-product">
        <a-picture
            v-if="background"
            :background="background"
            fit="cover"
            is-background
        />
        <div class="a-container">
            <dynamic-link class="s-header-lp-one-product__logo-link" :to="logoLink">
                <a-logo class="s-header-lp-one-product__logo" type="full" fill="white" />
            </dynamic-link>
            <div class="s-header-lp-one-product__product-title">
                {{ productTitle || productName }}
            </div>
            <div class="s-header-lp-one-product__wrap">
                <div class="s-header-lp-one-product__info">
                    <a-slice-header class="s-header-lp-one-product__header" :title="title" title-tag="h1" />
                    <div class="s-header-lp-one-product__lead">
                        {{ lead }}
                    </div>
                    <ul v-if="list" class="s-header-lp-one-product__list">
                        <li v-for="(item, index) in list" :key="index">
                            <div class="s-header-lp-one-product__list-name">
                                {{ item.name }}
                            </div>
                            <div class="s-header-lp-one-product__list-text">
                                {{ item.text }}
                            </div>
                        </li>
                    </ul>
                    <div v-if="!isMobile" class="s-header-lp-one-product__link">
                        <a-link
                            v-if="link"
                            class="s-header-lp-one-product__product-link"
                            v-bind="link"
                            :type="link.type || 'light'"
                            :size="link.size || 'paragraph'"
                        />
                    </div>
                </div>
                <div class="s-header-lp-one-product__product">
                    <div v-if="promo" class="s-header-lp-one-product__information">
                        <a-glyph class="s-header-lp-one-product__information-icon" name="attention" />
                        <a-dangerous-html :content="promo" />
                    </div>
                    <div v-if="product || products" class="s-header-lp-one-product__product-card">
                        <a-picture
                            v-if="image"
                            class="s-header-lp-one-product__product-image"
                            :link="image"
                            :alt="imageAlt"
                            fit="cover"
                        />
                        <div class="s-header-lp-one-product__product-header">
                            <div v-if="products.headline" class="s-header-lp-one-product__product-name">
                                {{ products.headline }}
                            </div>
                            <div v-if="products.text" class="s-header-lp-one-product__product-text">
                                {{ products.text }}
                            </div>
                        </div>
                        <a-product-cta :settings="{ hasLabel: true, hasDescription: true, ...productPageCTA }" />
                        <div class="s-header-lp-one-product__product-footer">
                            <div class="s-header-lp-one-product__buttons">
                                <a-button
                                    v-for="(button, i) in buttons"
                                    :key="i"
                                    class="s-header-lp-one-product__button"
                                    v-bind="button"
                                    :size="button.size || 's'"
                                    :type="button.type || getButtonType(i)"
                                    @click="handleClick(button.optionsSURL)"
                                />
                            </div>
                        </div>
                        <div v-if="media" class="s-header-lp-one-product__product-video">
                            <a-media v-bind="media" />
                        </div>
                        <div v-else-if="video" class="s-header-lp-one-product__product-video">
                            <a-video-card v-bind="video" :text="video?.title" />
                        </div>
                        <div v-if="awards" class="s-header-lp-one-product__product-awards">
                            <ul class="s-header-lp-one-product__award-list" :class="{ 'with-one-award': hasOneAward }">
                                <li
                                    v-for="(award, index) in awards"
                                    :key="index"
                                    class="s-header-lp-one-product__award-item"
                                >
                                    <figure class="s-header-lp-one-product__award">
                                        <div class="s-header-lp-one-product__award-image-wrap">
                                            <a-picture
                                                class="s-header-lp-one-product__award-image"
                                                :link="award.image"
                                                :alt="award.title"
                                            />
                                        </div>
                                        <figcaption class="s-header-lp-one-product__award-title">
                                            {{ award.title }}
                                        </figcaption>
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="isMobile" class="s-header-lp-one-product__link">
                    <a-link
                        v-if="link"
                        class="s-header-lp-one-product__product-link"
                        v-bind="link"
                        :type="link.type || 'light'"
                        :size="link.size || 'paragraph'"
                    />
                </div>
            </div>
        </div>
        <a-loader :is-visible="isVisibleSURL" />
    </section>
</template>

<script>
import { get } from 'lodash';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALoader from '@core/components/loader/loader.vue';
import ALogo from '@core/components/logo/logo.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';

const AWARDS_LIMIT = 3;

export default {
    name: 'SHeaderLpOneProduct',

    components: {
        APicture,
        ASliceHeader,
        AVideoCard,
        AButton,
        ALink,
        ALoader,
        ADangerousHtml,
        AMedia,
        ALogo,
        DynamicLink,
        AGlyph,
        AProductCta,
    },

    mixins: [contentMixin, styleMixin, breakpoint, purchasing, analytics, cleverbridge],

    props: {
        /**
         * Ability to change the link of the logo
         */
        logoLink: {
            type: String,
            default: '/',
        },

        background: {
            type: Object,
            default: undefined,
        },

        title: {
            type: String,
            default: '',
        },

        productTitle: {
            type: String,
            default: null,
        },

        list: {
            type: Array,
            default: undefined,
        },

        productId: {
            type: Number,
            default: null,
        },

        link: {
            type: Object,
            default: undefined,
        },

        products: {
            type: Object,
            default: undefined,
        },

        promo: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            default: '',
        },

        media: {
            type: Object,
            default: undefined,
        },

        video: {
            type: Object,
            default: undefined,
        },

        awardIds: {
            type: Array,
            default: () => [],
        },

        imageAlt: {
            type: String,
            default: '',
        },

        productPageCTA: {
            type: Object,
            default: undefined,
        },
    },

    computed: {
        awards() {
            return (
                this.awardIds.length &&
                this.awardIds
                    .map((pid) => this.$store.state.awards.items[pid])
                    .filter(Boolean)
                    .splice(0, AWARDS_LIMIT)
            );
        },

        hasOneAward() {
            return this.awards && this.awards.length === 1;
        },

        productData() {
            return this.$store.state.products.items?.[this.productId] || {};
        },

        product() {
            return this.productData?.parameters?.common || {};
        },

        productName() {
            return this.productData?.parameters?.common?.productName || this.products.name || '';
        },

        buttons() {
            const productButtons = this.product.buttons || this.products.buttons;
            return get(this.sliceSettings, 'buttons', productButtons);
        },
    },

    methods: {
        getButtonType(index) {
            return index === 0 ? 'action' : '';
        },

        getYear(str) {
            const date = new Date(str);
            return date.getFullYear();
        },

        handleClick(optionsSURL) {
            if (optionsSURL) {
                const options = this.storeOptionsSURL(optionsSURL);
                this.redirectToCleverbridge(options);
            }
        },
    },

    async serverPrefetch() {
        this.awardIds.forEach((aid) => this.$store.dispatch('awards/getAward', aid));
        await this.getLicenses(this.productPageCTA);
    },
};
</script>

<style lang="postcss" scoped>
.s-header-lp-one-product {
    position: relative;
    padding-top: 40px;
    background: linear-gradient(
        134.26deg,
        #193a83 1.43%,
        #143578 20.95%,
        #0e2f6d 40.47%,
        #082a63 59.98%,
        #042558 79.5%,
        #00204e 99.02%
    );

    &:deep(&__information) {
        background: linear-gradient(0deg, rgba(155, 194, 37, 0.15), rgba(155, 194, 37, 0.15)),
        #fff;
        border: 1px solid var(--av-fixed-success);
        border-radius: 4px;
        padding: 16px;
        font-size: 18px;
        line-height: 24px;
        color: var(--av-fixed-primary);
        display: flex;
        align-items: center;
        max-height: 56px;
        margin-bottom: 16px;

        &-icon {
            fill: var(--av-fixed-success);
            margin-inline-end: 8px;
        }

        b {
            font-weight: bold;
        }
    }

    &__header {
        width: 100%;
    }

    &__lead {
        color: #fff;
        @mixin lead;
        margin-top: 16px;
    }

    &__info {
        margin-bottom: 48px;
        @media (--viewport-tablet) {
            @mixin colls 6;
            padding-inline-end: 24px;
        }

        @media (--viewport-desktop) {
            @mixin colls 7;
            padding-inline-start: 0;
        }
        @media (--viewport-desktop-wide) {
            padding-inline-end: calc(8.3333% - 14.6667px);
        }
    }

    &__logo {
        width: 118px;
        height: 24px;
    }

    &__product-title {
        margin-top: 8px;
        @mixin display;
        color: #fff;
    }

    &__link {
        margin-top: 40px;
        position: relative;

        &:before {
            content: '';
            background: rgba(64, 139, 234, 0.15);
            width: 0;
            height: 1px;
            position: absolute;
            top: 0;

            @media(--viewport-tablet) {
                width: 147px;
            }
        }
    }

    &:deep(&__product) {
        width: 100%;
        @media (--viewport-tablet) {
            @mixin colls 6;
        }
        @media (--viewport-desktop) {
            @mixin colls 5;
        }

        &-image {
            height: 184px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &-card {
            background: var(--av-fixed-white);
            border: 1px solid var(--av-nav-secondary);
            box-shadow: 0 0 1px rgba(0, 32, 77, 0.1763), 0 2px 6px rgba(0, 32, 77, 0.1);
            border-radius: 4px;
            height: fit-content;
            overflow: hidden;
        }

        &-header {
            padding: 24px 24px 0;
        }

        &-link {
            margin-top: 16px;
            font-weight: 600;
            width: 100%;
            @media (--viewport-tablet) {
                width: auto;
            }
        }

        &-text {
            @mixin lead;
            color: var(--av-nav-primary);
        }

        &-name {
            color: var(--av-nav-primary);
            font-weight: bold;
            @mixin title-accent;
        }

        &-footer {
            padding: 24px;
        }

        &-buttons {
            display: flex;
        }

        &-video {
            padding-inline: 24px;
            margin-bottom: 24px;
        }

        &-awards {
            margin-inline: 24px;
            margin-bottom: 24px;
            border-top: 1px solid var(--av-brand-secondary-light);
            padding-top: 24px;
        }
    }

    &__award-list {
        grid-gap: 24px;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;

        &.with-one-award {
            .s-header-lp-one-product__award {
                @mixin paragraph-accent;
                display: flex;
                align-items: center;
                gap: 24px;
            }

            .s-header-lp-one-product__award-title {
                @mixin paragraph;
                font-weight: 500;
                margin-top: 0;
            }
        }
    }

    &__award {
        color: var(--av-fixed-light);
    }

    &__award-image-wrap {
        height: 48px;
    }

    &:deep(&__award-image) {
        display: block;
        height: 100%;
        max-width: 100%;
        object-fit: contain;

        .a-picture__img {
            margin-inline-start: 0;
        }
    }

    &__award-title {
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        margin: 8px 0 0;
    }

    &__award-year {
        display: block;
    }

    &__button {
        width: 100%;

        &:last-child {
            margin-top: 16px;
        }

        &:first-child {
            margin-top: 0;
        }

        @media (--viewport-tablet) {
            margin: 0;
        }

        @media (--viewport-desktop) {
            width: auto;
            min-width: 115px;
            &:last-child {
                margin-inline-start: 16px;
                margin-top: 0;
            }

            &:first-child {
                margin-inline-start: 0;
            }
        }
    }

    &__list {
        margin-top: 40px;

        li {
            margin-bottom: 16px;
            padding-inline-start: 32px;
            position: relative;
            max-width: 95%;

            &:last-child {
                margin-bottom: 0;
            }

            @media (--viewport-tablet) {
                max-width: 100%;
            }

            &::before {
                content: '';
                background: var(--av-brand-secondary);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                inset-inline-start: 10px;
                top: 10px;
            }
        }

        &-name {
            color: var(--av-fixed-white);
        }

        &-text {
            color: var(--av-fixed-white-light);
            display: none;
            @media (--viewport-desktop) {
                display: block;
            }
        }
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        @media (--viewport-tablet) {
            margin-top: 56px;
        }
        @media (--viewport-desktop) {
            margin-top: 64px;
        }
        @media (--viewport-desktop-wide) {
            margin-top: 56px;
        }
    }

    &:deep(.a-slice-header__title) {
        @mixin display;
        color: var(--av-fixed-white);

        @media (--viewport-desktop) {
            @mixin large;
        }
    }

    &:deep(.cta) {
        padding: 0 24px;

        .a-glyph {
            fill: var(--av-fixed-success-dark);
        }
    }

    &:deep(.cta-label) {
        font-weight: 700;
        margin: 24px 0 8px;
        color: var(--av-fixed-success-dark);
    }

    &:deep(.cta-description) {
        margin: 0;
    }
}
</style>
