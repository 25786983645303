<template>
    <s-basic-slice class="s-serial-number-tabs" v-bind="$attrs">
        <a-tabs :type="type">
            <a-tab v-for="(slice, index) in slices" :key="index" :label="slice.label">
                <component :is="slice.name" class="s-tabs__item" v-bind="{ ...commonData, ...slice.data }" />
            </a-tab>
        </a-tabs>
    </s-basic-slice>
</template>

<script>
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SSerialNumberTab from './serial-number-tab.vue';

export default {
    name: 'SSerialNumberTabs',
    components: {
        SBasicSlice,
        ATabs,
        ATab,
        SSerialNumberTab,
    },
    props: {
        slices: {
            type: Array,
            required: true,
        },

        messages: {
            type: Object,
            required: true,
        },

        type: {
            type: String,
            default: '',
        },

        inputPlaceholder: {
            type: String,
            default: 'Serial number',
        },

        submitText: {
            type: String,
            default: 'Submit',
        },

        downloadText: {
            type: String,
            default: 'Download now',
        },

        downloadTitle: {
            type: String,
            default: 'Download',
        },
    },
    data() {
        return {
            commonData: {
                inputPlaceholder: this.inputPlaceholder,
                submitText: this.submitText,
                downloadText: this.downloadText,
                downloadTitle: this.downloadTitle,
                messages: this.messages,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.s-serial-number-tabs {
    padding: 120px 0 40px;

    @media (--viewport-tablet) {
        padding: 152px 0 48px;
    }

    &:deep(.a-slice-header__title) {
        @mixin display;

        @media (--viewport-tablet) {
            @mixin large;
        }
    }

    &:deep(.a-tabs__item) {
        max-width: 214px;
    }
}
</style>
