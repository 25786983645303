<template>
    <s-basic-slice
        :id="id"
        class="s-slice s-header-simple"
        :class="{ ...basicClassName, combo: isCombo, 'nav-only': navOnly, breadcrumbs: hasBreadcrumbs }"
    >
        <h1
            v-if="productTitle"
            class="s-header-simple__product-title"
            :class="{ noLabel: !labelFlag }"
        >
            {{ productTitle }}
        </h1>
        <div v-if="labelFlag" class="s-header-simple__label-flag">
            {{ labelFlag }}
        </div>
        <a-breadcrumbs
            v-if="hasBreadcrumbs"
            :links="breadcrumbs"
            :sublinks="true"
            :class="{ 'space-top': !hasProduct }"
        />
        <h1
            v-if="title"
            class="s-header-simple__text-title"
            :class="{ 'space-top': !hasBreadcrumbs && !hasProduct }"
        >
            {{ title }}
        </h1>
        <div v-if="lead" class="s-header-simple__lead">
            <a-dangerous-html :content="lead" />
        </div>
    </s-basic-slice>
</template>

<script>
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SHeaderSimple',
    components: {
        ABreadcrumbs,
        ADangerousHtml,
        SBasicSlice,
    },
    mixins: [SBasicSlice],
    props: {
        productTitle: {
            type: String,
            required: false,
            default: undefined,
        },

        title: {
            type: String,
            required: false,
            default: undefined,
        },

        labelFlag: {
            type: String,
            required: false,
            default: undefined,
        },

        breadcrumbs: {
            type: Array,
            required: false,
            default: () => [],
        },

        isCombo: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        hasProduct() {
            return this.productTitle || this.labelFlag;
        },
        hasBreadcrumbs() {
            return Boolean(this.breadcrumbs && this.breadcrumbs.length);
        },
        navOnly() {
            return !this.productTitle && !this.hasProduct && !this.hasBreadcrumbs;
        },
    },
};
</script>

<style lang="postcss">
.s-header-simple {
    padding-top: 64px;

    &.combo {
        padding-bottom: 32px;

        @media (--viewport-desktop) {
            padding-bottom: 48px;
        }
    }
    &.nav-only {
        padding-top: 56px;
        padding-bottom: 0;
        @media (--viewport-desktop) {
            padding-top: 64px;
        }
    }
    &__product-title {
        @mixin colls 12;
        @mixin hero-subtle;
        margin: 8px 0;
        font-weight: 300;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin colls 6;
        }
        @media (--viewport-desktop) {
            @mixin colls 5;
            margin: 16px 0 8px;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 4;
            @mixin large;
        }
        &.noLabel {
            margin-bottom: 48px;
            @media (--viewport-desktop) {
                margin-bottom: 64px;
            }
        }
    }

    &__label-flag {
        @mixin paragraph;
        margin-bottom: 48px;
        font-weight: 600;
        color: var(--av-fixed-success);

        @media (--viewport-tablet) {
            @mixin colls 6;
        }
        @media (--viewport-desktop) {
            @mixin colls 5;
            margin-bottom: 64px;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 4;
        }
    }

    .a-breadcrumbs {
        position: relative;
        padding: 0;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 32px;
        }
        &:deep(.a-container) {
            padding: 0;
        }
    }

    &__text-title {
        @mixin colls 12;
        @mixin display;
        color: var(--av-nav-primary);
        @media (--viewport-mobile-wide) {
            @mixin hero;
        }
        @media (--viewport-desktop) {
            @mixin colls 8;
            @mixin large;
        }
    }
    &__lead {
        @mixin colls 12;
        @mixin lead;
        margin-top: 24px;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin title;
        }
        @media (--viewport-desktop) {
            @mixin colls 8;
        }
    }
    .space-top {
        padding-top: 64px;
        @media (--viewport-tablet) {
            padding-top: 56px;
        }
        @media (--viewport-desktop) {
            padding-top: 88px;
        }
    }
}
</style>
