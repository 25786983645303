import { render, staticRenderFns } from "./home-page-new.vue?vue&type=template&id=cf4f4ff6&scoped=true"
import script from "./home-page-new.vue?vue&type=script&lang=js"
export * from "./home-page-new.vue?vue&type=script&lang=js"
import style0 from "./home-page-new.vue?vue&type=style&index=0&id=cf4f4ff6&prod&lang=postcss&scoped=true"
import style1 from "./home-page-new.vue?vue&type=style&index=1&id=cf4f4ff6&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf4f4ff6",
  null
  
)

export default component.exports