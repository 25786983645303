<template>
    <div class="event-head">
        <div class="background-wrap">
            <a-picture
                class="background"
                :link="image"
                size="background"
                fit="cover"
            />
        </div>
        <div class="content">
            <div class="main">
                <a :href="backLink.to" class="event-link white back-link">
                    <a-glyph class="glyph left" name="arrow-left" />
                    {{ backLink.title }}
                </a>
                <div class="labels">
                    <event-label
                        v-for="label in labels"
                        :key="label.title"
                        class="label"
                        v-bind="label"
                    />
                </div>
                <h1 class="title">
                    <a-dangerous-html :content="computedTitle" tag="span" />
                </h1>
                <div v-if="action" class="action-wrap">
                    <a :href="action.to" class="event-button action">{{ action.title }}</a>
                </div>
                <event-head-features :features="features" />
            </div>
        </div>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import EventHeadFeatures from '../shared-components/event-head-features/event-head-features.vue';
import EventLabel from '../shared-components/event-label/event-label.vue';

export default {
    name: 'EventHead',

    components: {
        ADangerousHtml,
        APicture,
        EventHeadFeatures,
        AGlyph,
        EventLabel,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        features: {
            type: Array,
            default: null,
        },
        action: {
            type: Object,
            default: () => null,
        },
        labels: {
            type: Array,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        backLink: {
            type: Object,
            required: true,
        },
    },
    computed: {
        computedTitle() {
            if (this.$store.state.trial?.queryStringData?.company) {
                const fixedTitle = `${this.title[0].toLowerCase()}${this.title.slice(1)}`;
                return `${this.$store.state.trial.queryStringData.company}, ${fixedTitle}`;
            }
            return this.title;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-head {
    background: var(--av-nav-primary);
    position: relative;

    .content {
        margin: 0 auto;
        max-width: 1440px;

        @media (--viewport-desktop) {
            position: relative;
        }
    }

    .main {
        background: rgba(0, 32, 77, 0.6);
        color: var(--av-fixed-white);
        position: relative;
        z-index: 1;
        padding: 94px 16px 48px;

        @media (--viewport-tablet) {
            padding: 102px 32px 48px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @media (--viewport-desktop) {
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            padding: 108px 64px 48px;
        }
    }

    .back-link {
        text-align: center;
        margin-bottom: 64px;

        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    .labels {
        margin-top: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (--viewport-tablet) {
            justify-content: flex-start;
        }
    }

    .label {
        @media (--viewport-tablet) {
            margin-inline-end: 6px;
            margin-inline-start: 0;
        }
    }

    .title {
        @mixin display;
        color: var(--av-fixed-white);
        text-align: center;
        font-weight: 700;
        margin-bottom: 40px;
        word-break: break-word;

        @media (--viewport-tablet) {
            @mixin hero;
            text-align: start;
        }

        @media (--viewport-desktop) {
            @mixin large;
        }
    }

    .action-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 56px;

        @media (--viewport-tablet) {
            justify-content: flex-start;
        }
    }

    .action {
        @mixin paragraph-accent;
        padding: 12px 32px;
    }

    .background-wrap {
        display: none;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        width: 100%;
        height: 100%;

        @media (--viewport-desktop) {
            display: block;
        }
    }

    .background {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:deep(.a-picture__img) {
            max-width: none;
            height: 100%;
            width: 100%;
        }
    }
}
</style>
