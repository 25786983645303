<template>
    <div class="cpc-bar">
        <div v-if="links" class="categories">
            <div class="a-container">
                <div class="items">
                    <a-link
                        :class="{active: $route.name === 'blog-cpc-news' }"
                        class="link"
                        to="/cyber-protection-center/categories/news"
                        :text="newsTitle"
                        size="lead"
                    />
                    <a-link
                        v-for="(item, i) in computedLinks"
                        :key="`cpoc_categories_${i}`"
                        :class="{active: activeCategory === item.id }"
                        v-bind="item"
                        class="link"
                        :to="item.link"
                        :text="item.title"
                        :size="item.size || 'lead'"
                    />
                </div>
            </div>
        </div>
        <div class="a-container">
            <div v-if="title || lead" class="head">
                <h1 v-if="title" class="title">
                    {{ title }}
                </h1>
                <h2 v-if="lead" class="lead">
                    {{ lead }}
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SBlogCpcBar',
    components: {
        ALink,
    },
    props: {
        links: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        lead: {
            type: String,
            default: null,
        },
        newsTitle: {
            type: String,
            default: null,
        },
        activeCategory: {
            type: Number,
            default: null,
        },
    },
    computed: {
        computedLinks() {
            const allowedIds = [9, 8, 7];
            const allowedSlugs = ['malware-analysis', 'industry-insights', 'incident-reports'];
            return this.links.filter((item) => allowedIds.includes(item.id) || allowedSlugs.includes(item.slug));
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpc-bar {
    .head {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 24px;
        color: var(--av-fixed-white);
        text-align: center;
        @media (--viewport-tablet) {
            text-align: start;
            justify-content: space-between;
        }
        .title {
            font-weight: 700;
            margin-bottom: 16px;
            font-size: 32px;
            line-height: 56px;
            @media (--viewport-tablet) {
                font-size: 48px;
                width: 50%;
                margin-bottom: 0;
            }
            @media (--viewport-desktop) {
                font-size: 64px;
                width: auto;
                line-height: 72px;
            }
        }
        .lead {
            @mixin title;
            @media (--viewport-tablet) {
                width: 50%;
            }
            @media (--viewport-desktop) {
                @mixin display;
                width: auto;
                max-width: 525px;
            }
        }
    }
    .categories {
        background: rgba(0, 0, 0, 0.5);
        padding: 24px 0;
        width:100%;
        @media (--viewport-tablet) {
            padding: 8px 0;
        }
        .items {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column wrap;
            gap: 16px 0;
            @media (--viewport-tablet) {
                flex-flow: row wrap;
                gap: 8px;
            }
            @media (--viewport-desktop) {
                gap: 16px 60px;
            }
            .link {
                &:deep(.a-link__content) {
                    font-weight: 700;
                    padding: 4px 8px;
                    border-radius: 8px;
                    color: rgba(253, 184, 146, 1);
                }
                &:deep(.a-link__content__glyph) {
                    fill: rgba(253, 184, 146, 1);
                }
                &:hover {
                    &:deep(.a-link__content) {
                        background: rgba(122, 87, 69, 0.25);
                    }
                }
                &:focus, &:active, &.active {
                    &:deep(.a-link__content) {
                        background: rgba(253, 184, 146, 0.25);
                    }
                }
            }
        }
    }
}
</style>
