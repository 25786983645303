<template>
    <section v-if="content" class="main-page-new__stats" :class="basicClassName">
        <div class="a-container">
            <div v-if="content.title" class="title">
                {{ content.title }}
            </div>
            <div class="items">
                <div
                    v-for="(item, i) in content.numbers"
                    :key="`main-page-new-stats_${i}`"
                    class="item"
                >
                    <strong>{{ item.value }}</strong>
                    <span>{{ item.label }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SMainPageStats',
    mixins: [contentMixin, styleMixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new__stats {
    text-align: center;
    color: var(--av-nav-primary);
    padding-top: 64px;
    @media (--viewport-tablet) {
        padding-top: 174px;
    }
    @media (--viewport-desktop) {
        padding-top: 72px;
    }
    .title {
        @mixin hero;
        margin-bottom: 32px;
        @media (--viewport-tablet) {
            @mixin large;
        }
    }
    .items {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 62px;
        @media (--viewport-desktop) {
            gap: 80px;
        }
        .item {
            @mixin colls 12;
            @media (--viewport-tablet) {
                width: auto;
            }
            strong {
                @mixin large;
                background: linear-gradient(90deg, rgb(148, 206, 241) 0%, rgb(0, 101, 227) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
                display: block;
                margin-bottom: 8px;
                @media (--viewport-desktop) {
                    @mixin large-subtle;
                    font-weight: 700;
                }
            }
            span {
                @mixin title;
                line-height: 24px;
                font-weight: 500;
                color: var(--av-fixed-secondary);
            }
        }
    }
}
</style>
