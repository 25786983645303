<template>
    <div class="wrapper">
        <a-picture :background="background" is-background fit="cover" />
        <s-basic-slice :id="id" class="s-header-technology">
            <div class="s-header-technology__content">
                <div class="s-header-technology__top">
                    <div v-if="category" class="s-header-technology__category">
                        {{ category }}
                    </div>
                    <h1 class="s-header-technology__title">
                        {{ title }}
                    </h1>
                </div>
            </div>
            <a-sub-menu
                v-if="subMenu"
                v-sticky="{ parent: '.s-header-technology' }"
                :product-name="title"
                :not-glyph="true"
                v-bind="subMenu"
            />
        </s-basic-slice>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import ASubMenu from '@core/components/sub-menu/sub-menu.vue';
import Sticky from '@core/directives/sticky.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SHeaderTechnology',

    components: {
        APicture,
        SBasicSlice,
        ASubMenu,
    },
    directives: {
        Sticky,
    },
    props: {
        id: {
            type: String,
            required: true,
        },

        background: {
            type: Object,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        category: {
            type: String,
            default: '',
        },

        subMenu: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.wrapper {
    position: relative;
}
.s-header-technology {
    color: var(--av-fixed-white);
    background-color: var(--av-nav-primary);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 51px 0 24px;

    &__content {
        padding: 96px 0 0;
        position: relative;
    }

    &__title {
        @mixin display;

        @media (--viewport-mobile-wide) {
            @mixin hero;
        }
        @media (--viewport-desktop) {
            @mixin large;
        }
    }

    &__category {
        @mixin fineprint-heading;
        margin: 0 0 8px;
    }

    @media (--viewport-mobile-wide) {
        padding-bottom: 40px;

        &__content {
            padding: 112px 0 0;
        }
    }

    @media (--viewport-tablet) {
        padding-top: 67px;
    }

    @media (--viewport-desktop) {
        padding-top: 72px;

        &__category {
            max-width: 66.66%;
        }

        &__title {
            max-width: 66.66%;
        }
    }

    @media (--viewport-desktop-large) {
        background-size: 2560px;
    }
}
</style>
