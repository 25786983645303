<template>
    <div class="a-container">
        <div class="blog-cpc-cards">
            <h3 class="title">
                {{ title }}
            </h3>
            <blog-cpc-list class="list" :cards="cards" :is-loading="isLoading" />
            <slot name="pagination" />
        </div>
    </div>
</template>

<script>
import BlogCpcList from './shared-components/blog-cpc-list.vue';

export default {
    name: 'SBlogCpsCards',
    components: {
        BlogCpcList,
    },
    props: {
        title: {
            type: String,
            default: null,
        },

        cards: {
            type: Array,
            required: true,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-cpc-cards {
    padding-bottom: 64px;
    @media (--viewport-tablet) {
        padding-bottom: 72px;
    }
    @media (--viewport-desktop-wide) {
        padding-bottom: 56px;
    }
    .title {
        @mixin display-accent;
        color: rgba(253, 184, 146, 1);
        margin-bottom: 24px;
        @media (--viewport-desktop) {
            @mixin hero-accent;
        }
    }
}
</style>
