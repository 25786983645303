<template>
    <div class="a-container">
        <div class="cpc-insights">
            <div v-if="title" class="title">
                {{ title }}
            </div>
            <div class="row first">
                <div class="spotlight">
                    <blog-cpc-card
                        :key="spotlightedCard?.id"
                        class="item"
                        v-bind="{ ...spotlightedCard }"
                        image_size="normal"
                        :persist-original="true"
                        :max-length-title="90"
                    />
                </div>
                <div class="posts">
                    <blog-cpc-card
                        v-for="card in secondCol"
                        :key="card?.id"
                        class="item"
                        v-bind="{ ...card }"
                        hide-image
                        :max-length-title="90"
                    />
                </div>
            </div>
            <div class="row second">
                <blog-cpc-card
                    v-for="card in secondRow"
                    :key="card?.id"
                    class="item"
                    v-bind="{ ...card }"
                    hide-image
                    :max-length-title="90"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import BlogCpcCard from './shared-components/blog-cpc-card.vue';

export default {
    name: 'SBlogCpcIntro',
    components: {
        BlogCpcCard,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        cards: {
            type: Array,
            default: () => ([]),
        },
    },
    computed: {
        spotlightedCard() {
            return this.cards[0];
        },
        secondCol() {
            return this.cards.slice(1, 3);
        },
        secondRow() {
            return this.cards.slice(3, 6);
        },
    },
    methods: {
        formatDescription(str) {
            return truncate(str, {
                length: 105,
                separator: ' ',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpc-insights {
    .title {
        @mixin display-accent;
        margin-bottom: 32px;
        color: rgba(253, 184, 146, 1);
        @media (--viewport-desktop) {
            @mixin hero-accent;
        }
    }
    .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
        gap: 16px;
        @media (--viewport-tablet) {
            flex-direction: row;
        }
        .spotlight {
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 7;
            }
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            &:deep(.title) {
                @mixin title-accent;
            }
        }
        .posts {
            display: flex;
            flex-direction: column;
            gap: 16px;
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 5;
            }
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
        }
        &.second {
            margin: 0;
            .item {
                @mixin colls 12;
                @media (--viewport-tablet) {
                    @mixin colls 4;
                }
            }
        }
    }
}
</style>
